import { icons } from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { NavigateStructure } from "../interface/nav";
import LocalStorageService from "../services/localStorage";
import flow from "../assets/flow.json";
import { hasAuditRole } from "./getNavigationPages";
const storageKey = "afy-login";
const localStorageService = new LocalStorageService(storageKey);

export const GetMenuSideBarHome = () => {
  const user = localStorageService.getUserLoginStored();

  const m: NavigateStructure[] = flow.data.salesMenu.navigationMenu;
  const x: any[] = [
    {
      id: "venta",
      name: "Nueva Cotización Auto",
      icon: "icons.Shield",
      active: true,
      route: "/",
      divider: false,
    },
    {
      id: "poliza",
      name: "Cotizaciones",
      icon: "icons.Shield",
      active: false,
      route: "/insurance-policies-summary",
      divider: false,
    },
    {
      id: "clientes",
      name: "Clientes",
      icon: "icons.Person",
      active: false,
      route: "/clients",
      divider: false,
    },
  ];
  //Apap Auto audit
  const fiscalMenu: any[] = [
    {
      id: "venta",
      name: "Nueva Cotización Auto",
      icon: "icons.Shield",
      active: true,
      route: "/",
      divider: false,
    },
    {
      id: "poliza",
      name: "Cotizaciones",
      icon: "icons.Shield",
      active: false,
      route: "/insurance-policies-summary",
      divider: false,
    },
  ];

  if (["apap-auto", "bancounion"].includes(user.subdomain || "")) {
    if (hasAuditRole("audit")) {
      return fiscalMenu;
    }
    return x;
  }
  return m;
};

export const GetMenuHeader = () => {
  const user = localStorageService.getUserLoginStored();

  const m: any[] = [
    {
      id: "inicio",
      name: "Inicio",
      active: true,
    },
    {
      id: "admin",
      name: "Administración",
      active: false,
    },
    {
      id: "reporte",
      name: "Reportes",
      active: false,
    },
  ];
  //Apap Auto
  const x: any[] = [
    {
      id: "inicio",
      name: "Ventas Auto",
      active: true,
    },

    {
      id: "reporte",
      name: "Reportes",
      active: false,
    },
  ];

  if (["apap-auto", "bancounion"].includes(user.subdomain || "")) {
    if (hasAuditRole("audit")) {
      return [];
    }
    return x;
  }
  return m;
};

export const GetMenuSideBarAdministration = () => {
  const m: NavigateStructure[] = flow.data.adminMenu.navigationMenu;

  return m;
};

export const GetMenuSideBarReport = () => {
  const m: NavigateStructure[] = flow.data.reportsMenu.navigationMenu;
  return m;
};
