import React from "react";
import {
  breadcrumb,
  BreadcrumbAFY,
  FormApplicantSecuredDeclarationAFY,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";

interface ClientsViewProps {
  title?: string;
  breadcrumbs?: breadcrumb[];
  showInfo?: boolean;
  tableHeight?: string;
  filters?: any[];
  stepProgress?: any;
  resumeDescription?: string;
  resumeValue?: string;
  nextLabel?: string;
  previousLabel?: string;
  onClickPrevious?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onClickNext?: (data: any) => void;
  nextDisabled?: boolean;
  showPrevious?: boolean;
  hideFilter?: boolean;
  steps?: any;
}

export const ApplicantDeclarationView = (props: ClientsViewProps) => {
  return (
    <React.Fragment>
      <HeaderTitlePage
        breadCrumbs={props.breadcrumbs}
        titlePage={props.title}
        steps={props.steps}
        hideStep={false}
        stepsTop={"-20px"}
      />
      
      <FormApplicantSecuredDeclarationAFY
        title={""}
        showNext={true}
        nextLabel={"Siguiente"}
        buttonPosition={"top"}
        isCollapsible={false}
        minHeightGrid={"calc(100vh - 73px)"}

        previousLabel={"Anterior"}
        showInfo={true}
        hideFilter={props.hideFilter}
        nextDisabled={props.nextDisabled}
        resumeDescription={props.resumeDescription}
        resumeValue={props.resumeValue}
        titleQuestionnaire={""}
        showNextQuestionnaire={true}
        nextText={"Guardar"}
        collapsed={false}
        onClickNext={props.onClickNext}
        data={[
          {
            "label": "¿Está usted actualmente embarazada (mujer)?",
            "type": "toggle",
            "name": "pregnant",
            "childrenDescription": "Especifique el tiempo de embarazo",
            "children": [
              {
                "label": "Mucho tiempo:",
                "type": "select",
                "name": "drug_use_time_pregnant",
                "selectOptions": [
                  {
                    "id": "100",
                    "name": "mas de 1 año",
                  },
                  {
                    "id": "200",
                    "name": "1-5 años",
                  }
                ]
              }
            ]

          },
          {
            "label": "¿Usa usted o ha usado drogas ilegales o sustancias controladas?",
            "type": "toggle",
            "name": "drug_use",
            "childrenDescription": "Especifique el tiempo de consumo",
            "children": [
              {
                "label": "Mucho tiempo:",
                "type": "radio",
                "name": "drug_use_time",
                "radioOptions": [{
                  "id": "1",
                  "name": "Less than 1 year",
                }, {
                  "id": "2",
                  "name": "1-5 years",
                }]
              },
            ]
          },
          {
            "label": "¿Ha tenido resultados positivos de una prueba del HIV? ¿Padece o ha recibido tratamiento o consejo para SIDA, complejo relacionado al SIDA o cualquier otro desorden inmunológico?",
            "type": "toggle",
            "name": "hiv"
          },
          {
            "label": "¿Fuma?",
            "type": "toggle",
            "name": "smoking"
          },
          {
            "label": "¿Ha tenido que recibir tratamiento médico en hospitalización o unidad de cuidados intensivos por el COVID? En caso afirmativo, ¿Requirió la ventilación mecánica?",
            "type": "toggle",
            "name": "covid_treatment"
          },
          {
            "label": "¿A la fecha, mantiene alguna enfermedad producto del COVID?",
            "type": "toggle",
            "name": "covid_disease"
          }
        ]}
        filters={props.filters}
      />
    </React.Fragment>
  );
};
