import React from "react";
import {
  AuditFormAFY,
  breadcrumb,
  DataFormClient,
  DocumentProps,
  LoadingAFY,
  ModalComponentAFY,
  PDFViewerAFY,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../../assets/themes/theme";
import { ColumnsGrid, filtersData } from "./auditPageDTO";
import { StatusAuditDocument } from "../../../enum/statusAuditDocument";

export const AuditPageView = (props: {
  title: string;
  loading: boolean;
  breadCrumbs: breadcrumb[];
  filtersData: any;
  handleClickContextMenu: (item: any, param: any) => void;
  quoteId?: string;
  dataForm: DataFormClient;
  rows: any[];
  openDrawer: boolean;
  quoteUploadAttachments: (file: File[]) => void;
  onCloseDrawer: () => void;
  attachDocuments: any[];
  onBack?: () => void;
  allDocumentsApproved: boolean;
  onClickNext?: () => void;
  openModal?: boolean;
  setOpenModal: React.Dispatch<React.SetStateAction<boolean>>;
  /**/
  documents?: DocumentProps;
  approveDocument: (status: StatusAuditDocument, comment: string) => void;
  url: string;
  t: any;
  titleDrawer: string;
  txtLegendDrawer: string;
  titleButtonDrawer: string;
  placeHolderDrawer: string;
  subTitleDrawer: string;
  onValueApply?: (value: any) => void;
  openDrawerRejed: boolean;
  setOpenDrawerRejed: React.Dispatch<React.SetStateAction<boolean>>;
}) => {
  return (
    <React.Fragment>
      <ModalComponentAFY
        openModal={props.openModal}
        backgroundColor="#ffffff"
        onClose={() => props.setOpenModal(false)}
        border={0}
        minWidthModal={"90%"}
        padding={15}
        modalHeight={"90%"}
      >
        <PDFViewerAFY
          view={2}
          title={props.documents?.name ?? ""}
          description=""
          titleButtonBack="Cerrar"
          titleButtonTopLeft="Aprobar"
          titleButtonTopRight="Rechazar"
          titlePagination1="Página"
          titlePagination2="de"
          bgColorButtonTopLeft="#3A56A1"
          bgColorButtonTopRight="#ebebeb"
          textColorButtonTopLeft="#fff"
          textColorButtonTopRight="#3A56A1"
          padding={30}
          onBack={() => {
            props.setOpenModal(false);
          }}
          onClickButtonTopLeft={() => {
            props.approveDocument(
              StatusAuditDocument.APPROVED,
              props.t("pdfViewer.messageDefault"),
            );
          }}
          onClickButtonTopRight={() => {
            props.setOpenDrawerRejed(true);
          }}
          onClose={function noRefCheck() {}}
          url={props.url}
          openModal={false}
          setOpenModal={() => {}}
          /*Drawer Properties*/
          titleDrawer={props.titleDrawer}
          textLegendDrawer={props.txtLegendDrawer}
          titleBtnDrawer={props.titleButtonDrawer}
          placeHolderDrawer={props.placeHolderDrawer}
          subTitleDrawer={props.subTitleDrawer}
          openDrawer={props.openDrawerRejed}
          onValueApply={props.onValueApply}
          onCloseDrawer={() => {
            props.setOpenDrawerRejed(false);
          }}
        />
      </ModalComponentAFY>
      <LoadingAFY
        loading={props.loading}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={"Cargando..."}
        bgColor={themeCore.colors.backgroundLoader}
      />
      <AuditFormAFY
        title={props.title}
        titlePage={props.title}
        firstSubtitle="Información de cotización"
        secondSubtitle="Información general del cliente"
        thirdSubtitle="Información de ubicación"
        fourthSubtitle="Información de contacto"
        tab1Description="Documentos de sustento"
        abstractTitle="Resumen"
        titleDrawer={"Suba el documento solicitado."}
        descriptionDrawer={
          "Arrastra el documento solicitado a la zona de color o haz clic en el área de color para seleccionarlo. Solo puedes subir un documento a la vez, y solo se permiten archivos en formato PDF."
        }
        titleUpload={"Subir"}
        subTitleUpload={"Arrastre y suelte su archivo."}
        heightDrawer={"100%"}
        abstractTitleColor="#3A56A1"
        widthGrid="100%"
        rowHeightGrid={45}
        heightGrid="300px"
        pageSizeGrid={5}
        mask="9999-9999-9999-9999"
        previousLabel="Anterior"
        nextLabel="Siguiente"
        minHeightGrid={"calc(100vh - 140px)"}
        breadcrumbOptions={props.breadCrumbs}
        openDrawerHistory={props.openDrawer}
        collapsedTab1={false}
        collapsedTab2={true}
        collapsedTab3={true}
        collapsedTab4={true}
        showNextPrevButtons={true}
        nextDisabled={!props.allDocumentsApproved}
        Tab1Columns={ColumnsGrid(props.handleClickContextMenu)}
        Tab1Rows={props.rows}
        dataForm={props.dataForm}
        filters={filtersData(props.filtersData)}
        onNext={() => {}}
        onBack={props.onBack}
        onUpload={props.quoteUploadAttachments}
        onCloseDrawer={props.onCloseDrawer}
        regex={/[0-9]{4}-[0-9]{4}-[0-9]{4}-[0-9]{4}/}
        onBlurInput={() => {}}
        onChangeInput={() => {}}
        onClickNextButton={props.onClickNext}
      />
    </React.Fragment>
  );
};