import React from "react";
import "./style.css";

import {
  AdminUserAFY,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { ModalDelete } from "./resources/modalDelete";
import { Rol } from "../../model/rol";
import { ModalConfirmationDelete } from "../insurances/productCreation/resources/modalConfirmationDelete";

export const View = (props: {
  // Props Admin
  title: string;
  columns: any;
  buttonTextAdd?: string;
  onClickAdd: () => void;
  permissions: any;

  // Props Form
  titleForm?: string;
  dataForm: any;
  editMode: boolean;
  branchTypeOptions?: Array<any>;
  roleTypeOptions?: Array<any>;
  intermediaryTypeOptions?: Array<any>;
  genderTypeOptions?: Array<any>;
  identificationTypeOptions?: Array<any>;
  statusOptions?: Array<any>;
  emailText?: string;
  emailRequired?: string;
  emailError?: string;
  emailHelperText?: string;
  nameText?: string;
  nameRequired?: string;
  nameHelperText?: string;
  lastNameText?: string;
  lastNameRequired?: string;
  lastNameHelperText?: string;
  passwordText?: string;
  passwordRequired?: string;
  passwordError?: string;
  passwordHelperText?: string;
  passwordConfirmText?: string;
  passwordConfirmRequired?: string;
  passwordConfirmError?: string;
  passwordConfirmHelperText?: string;
  birthDateText?: string;
  birthDateHelperText?: string;
  birthDateRequired?: string;
  identificationTypeText?: string;
  companyTypeText?: string;
  companyTypeOptions?: string;
  branchTypeText?: string;
  roleTypeText?: string;
  intermediaryTypeText?: string;
  genderTypeText?: string;
  identificationNumberText?: string;
  identificationNumberRequired?: string;
  identificationNumberHelperText?: string;
  userNameText?: string;
  userNameRequired?: string;
  userNameHelperText?: string;
  userformText?: string;
  imageUrl?: string;
  activeStep: number;
  buttonText?: string;
  buttonNextText?: string;
  buttonBackText?: string;
  onSaveData: any;

  // Props Drawer
  openDrawer: boolean;
  textDescription: string;
  onCloseDrawer: () => void;

  // Props Search
  filterStatus: (item: any) => void;
  searchFilter: (term: any) => void;
  onCloseSearch: () => void;

  openModalDeleteRole: boolean;
  setOpenModalDeleteRole: (value: boolean | (() => boolean)) => void;

  list: any;
  openModal: boolean;
  openModalConfirm: boolean;
  openModalCloneConfirm: boolean;
  modalTitle: string;
  openModalDelete: boolean;
  setOpenModalDelete: (value: boolean | (() => boolean)) => void;
  clickView: (view: MenuItem) => void;
  onSubmit: () => void;
  handleOpenModal: () => void;
  handleOpenModalConfirm: () => void;
  handleOpenModalCloneConfirm: () => void;
  onClickButtonOptionsDropdown: () => void;
  onClickContextMenu: () => void;

  //  Rol
  onCloseDrawerRolsUser: () => void;
  openDrawerRoles: boolean;
  rowsRols: Rol[];
  listDropDownRols: any[];
  columnRols: any[];
  buttonLblAddGrid: string;
  labelDropDownRols: string;
  txtSearchDropDownRols: string;
  defaultDropDownRols: any[];
  titleDrawerRol: string;
  textDescriptionRol: string;
  onClickSaveDataRol: (e: React.MouseEvent<HTMLButtonElement>) => void;
  titleDelete?: string;
  subTitleDelete?: string;
  modalTitleRol: string;
  onSubmitDeleteRol: () => void;
}) => {
  return (
    <>
      <ModalDelete
        openModalConfirmDelete={props.openModalDelete}
        setOpenModalDelete={props.setOpenModalDelete}
        modalTitle={props.modalTitle}
        onClickDelete={props.onSubmit}
      />

      <ModalConfirmationDelete
        openModal={props.openModalDeleteRole}
        setOpenModal={props.setOpenModalDeleteRole}
        titleDelete={props.titleDelete}
        subTitleDelete={props.subTitleDelete}
        deleteRecurrence={props.onSubmitDeleteRol}
        deletePaymentMode={props.onSubmitDeleteRol}
      />

      <AdminUserAFY
        // Filter Props
        permissions={props.permissions}
        // showSelectStatus={true}
        // showSelectBranch={true}
        // showSelectRole={true}
        // titleButtonClear="Limpiar filtros"
        // Drawer Props
        openDrawer={props.openDrawer}
        // Admin Props
        title="Usuarios"
        buttonTextAdd="Agregar usuario"
        buttonText="Guardar"
        columns={props.columns}
        rows={props.list}
        onBack={() => {}}
        onNext={function noRefCheck() {}}
        onClickAdd={props.onClickAdd}
        // Form Props
        titleForm={props.titleForm}
        onSaveData={props.onSaveData}
        editMode={props.editMode}
        emailText={props.emailText}
        emailRequired={props.emailRequired}
        emailError={props.emailError}
        nameText={props.nameText}
        nameRequired={props.nameRequired}
        lastNameText={props.lastNameText}
        lastNameRequired={props.lastNameRequired}
        passwordText={props.passwordText}
        passwordRequired={props.passwordRequired}
        passwordError={props.passwordError}
        passwordConfirmText={props.passwordConfirmText}
        passwordConfirmRequired={props.passwordConfirmRequired}
        passwordConfirmError={props.passwordConfirmError}
        birthDateText={props.birthDateText}
        birthDateRequired={props.birthDateRequired}
        identificationTypeText={props.identificationTypeText}
        identificationTypeOptions={props.identificationTypeOptions}
        companyTypeText={props.companyTypeText}
        //companyTypeOptions: [{id: 1, name: "Company1", value:"1"}, {id: 2, name: "Company2", value:"2"}],
        branchTypeText={props.branchTypeText}
        branchTypeOptions={props.branchTypeOptions}
        roleTypeText={props.roleTypeText}
        roleTypeOptions={props.roleTypeOptions}
        intermediaryTypeText={props.intermediaryTypeText}
        //intermediaryTypeOptions: [{id: 1, name: "Intermediario1", value:"1"}, {id: 2, name: "Intermediario2", value:"2"}],
        genderTypeText={props.genderTypeText}
        genderTypeOptions={props.genderTypeOptions}
        identificationNumberText={props.identificationNumberText}
        identificationNumberRequired={props.identificationNumberRequired}
        identificationNumberHelperText={props.identificationNumberHelperText}
        userNameText={props.userNameText}
        userNameRequired={props.userNameRequired}
        userNameHelperText={props.userNameHelperText}
        textDescription={props.textDescription}
        activeStep={1}
        buttonNextText={props.buttonNextText}
        buttonBackText={props.buttonBackText}
        //unkown props
        onBlurSearch={function noRefCheck() {}}
        onChangeData={props.filterStatus}
        onCloseSearch={props.onCloseSearch}
        onChangeSearch={props.searchFilter}
        onChangeSelect={function noRefCheck() {}}
        onClickButtonOptionsDropdown={function noRefCheck() {}}
        onClickButtonView={function noRefCheck() {}}
        onClickContextMenu={function noRefCheck() {}}
        onClickDetails={function noRefCheck() {}}
        onClickSeeDetails={function noRefCheck() {}}
        onCloseDrawer={props.onCloseDrawer}
        dataForm={props.dataForm}
        //Drawer Rols
        openDrawerRol={props.openDrawerRoles}
        listDropDownRol={props.listDropDownRols}
        columnsRol={props.columnRols}
        rowsRol={props.rowsRols}
        buttonLblAddGrid={props.buttonLblAddGrid}
        labelDropDownRol={props.labelDropDownRols}
        txtSearchDropDownRol={props.txtSearchDropDownRols}
        defaultDropDownRol={props.defaultDropDownRols}
        titleDrawerRol={props.titleDrawerRol}
        textDescriptionRol={props.textDescriptionRol}
        onCloseDrawerRol={props.onCloseDrawerRolsUser}
        onClickSaveRolUser={props.onClickSaveDataRol}
      />
    </>
  );
};
