import React from "react";
import {
  breadcrumb,
  CardProps,
  InsuranceAFY,
  LoadingAFY,
  MenuItem,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { InsurancePageProps } from "./insuranceDto";
import { themeCore } from "../../assets/themes/theme";
import "./style.css";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";
import { useTranslation } from "react-i18next";

export const InsuranceView = (props: {
  insurance: InsurancePageProps;
  loading: boolean;
  breadCrumbs: breadcrumb[];
  searchFilter: (term: string) => void;
  clickView: (view: MenuItem) => void;
  clickCard: (card: CardProps) => void;
  clickChip: (chips: MenuItem[]) => void;
  insurancePage: number;
  insuranceLimit: number;
  countPagination: number;
  onChangePage: (e: number) => void;
  onChangeRowsPerPage: (e: number) => void;
  steps: any;
  showIcon: boolean;
  iconPage: any;
  logoImg: string;
}) => {
  const { t } = useTranslation();
  return (
    <>
      <HeaderTitlePage
        breadCrumbs={props.breadCrumbs}
        titlePage={props.insurance.titlePage}
        steps={props.steps}
        iconPage={props.iconPage}
        logoImg={props.logoImg}
        showIcon={props.showIcon}
        hideStep={true}
      />
      <LoadingAFY
        loading={props.loading}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={t("product.loading")}
        bgColor={themeCore.colors.backgroundLoader}
      />
        <InsuranceAFY
            data={props.insurance.data}
            filter={{
              backgroundColor: props.insurance.filter.backgroundColor,
              bgChipInsurers: props.insurance.filter.bgChipInsurers,
              bgChipInsurersSelected: props.insurance.filter.bgChipInsurersSelected,
              bgColorButton: props.insurance.filter.bgColorButton,
              bgColorIconButton: props.insurance.filter.bgColorIconButton,
              bgColorIconButtonSelected:
                props.insurance.filter.bgColorIconButtonSelected,
              colorIconButton: props.insurance.filter.colorIconButton,
              colorIconButtonSelected:
                props.insurance.filter.colorIconButtonSelected,
              colorIconChip: props.insurance.filter.colorIconChip,
              colorText: props.insurance.filter.colorText,
              insurers: props.insurance.filter.insurers,
              title: props.insurance.filter.title,
              titleFirstChip: props.insurance.filter.titleFirstChip,
            }}
            onChangeFilterTerm={(term) => {
              props.searchFilter && props.searchFilter(term);
            }}
            onClick={(item) => props.clickCard && props.clickCard(item)}
            onClickButtonView={(option) => {
              props.clickView && props.clickView(option);
            }}
            onClickChip={(menu) => {
              props.clickChip && props.clickChip(menu);
            }}
            onCancelSearch={() => {
              props.searchFilter && props.searchFilter("");
            }}
            titlePagination={t('recordsPerPage')}
            countPagination={props.countPagination}
            rowsPerPage={props.insuranceLimit}
            onChangePage={props.onChangePage}
            rowsPerPageOption={[20, 25, 50, 100]}
            onChangeRowsPerPage={props.onChangeRowsPerPage}
            pagePagination={props.insurancePage}
        />
    </>
  );
};
