import React, { ChangeEvent, useEffect, useState } from "react";
// @ts-ignore
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { PolicyRequest } from "../../model/policy";
import { dataFilter, paymentSummaryStorybook } from "./paymentSummaryDto";
import {
  policyCreate,
  policyCreateClearData,
} from "../../store/policy/actions";
import {
  breadcrumb,
  DataFormClient,
  DataFormPayment,
  iconsSvg,
  LoadingAFY,
  MenuItem,
  position,
  select,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { PlanModel } from "../../model/plan";
import { PaymentSummaryView } from "./view";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../dataBase/stepProgress";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import { themeCore } from "../../assets/themes/theme";

import { useGetCatalogAccountType } from "../../fnx/getCatalogAccountType";
import { quoteOne } from "../../store/generateQuote/actions";
import { showErrorToast } from "../../fnx/showError";
import showToast from "../../services/toast";
import { useTranslation } from "react-i18next";
import { catalogAccountTypeClearData } from "../../store/plan/actions";
import { STEP_PAYMENTMETHOD } from "../../const/catalog";
import { personByIdClearData } from "../../store/person/actions";
import { showToastSuccessMessage } from "../../fnx/sendSuccessMessage";
import { GetPersonById } from "../../fnx/person/getPersonById";
import { HeaderTitlePage } from "../../components/resources/headerTitlePage/headerTitlePage";

const STEP = STEP_PAYMENTMETHOD;
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);
const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);

const PaymentSummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [trigger, setTrigger] = useState(false);
  const [accountName, setAccountName] = useState("");
  const [accountType, setAccountType] = useState("");
  const [accountList, setAccountList] = useState([]);
  const [personId, setPersonId] = useState<string>("");
  const [accountNumber, setAccountNumber] = useState("");
  const [personTrigger, setPersonTrigger] = useState(false);
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [subTitlePayment, setSubTitlePayment] = useState<string>("");
  const [listAccountTypes, setListAccountTypes] = useState<select[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [dataFormPayment, setDataFormPayment] = useState<DataFormPayment>({});
  const [projectId] = React.useState<string | undefined>(
    localStorageService.getStepStored().steps[Steps.insurance].id
  );
  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || ""
  );
  const [quote] = React.useState<string | undefined>(
    localStorageService.getStepStored().steps[Steps.customer].quoteId
  );

  const [paymentListOptions, setPaymentListOptions] = useState([{}]);
  const [paymentListOption, setPaymentListOption] = useState({});
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [dataForm, setDataForm] = useState<
    DataFormClient & { Insurance?: string } & { CoverType?: string } & {
      PaymentMode?: string;
    } & { Currency?: string } & { Prima?: string } & { QuoteId?: string }
  >({
    Name: "",
    LastNames: "",
    DocumentNumber: "",
    Email: "",
    Insurance: "",
    PlanTypeName: "",
    CampaignName: "",
    CoverType: "",
    PaymentMode: "",
    Currency: "",
    Prima: "",
    QuoteId: "",
  });

  const { responseCreatePolicy, errorCreatePolicy, loadingCreatePolicy } =
    useSelector((state: any) => ({
      responseCreatePolicy: state.Policy.responseCreatePolicy,
      errorCreatePolicy: state.Policy.errorCreatePolicy,
      loadingCreatePolicy: state.Policy.loadingCreatePolicy,
    }));

  const { responseCatalogAccountType, errorCatalogAccountType } =
    useGetCatalogAccountType(trigger, projectId);

  const { responsePersonById, errorPersonById, loadingPersonById } =
    GetPersonById(personTrigger, personId);

  const { responseOneQuote, errorOneQuote, loadingOneQuote } = useSelector(
    (state: any) => ({
      responseOneQuote: state.Quote.responseOneQuote?.data,
      errorOneQuote: state.Quote.errorOneQuote,
      loadingOneQuote: state.Quote.loadingOneQuote,
    })
  );

  useEffect(() => {
    dispatch(policyCreateClearData());
    setTrigger(true);
    setSteps(stepProgress(STEP));
    setBreadCrumbs(stepProgress(STEP, true));

    dispatch(
      quoteOne({
        id: quote,
        fullBody: true,
      })
    );
  }, []);

  useEffect(() => {
    if (responsePersonById?.data) {
      const accountListNew = responsePersonById.data.additionalContentMap?.map(
        (accounts: any, idx: number) => ({
          id: idx + 1,
          cardTitles: [
            t(`paymentMethod.cardTitles.accountType`),
            t(`paymentMethod.cardTitles.accountNumber`),
            t(`paymentMethod.cardTitles.accountHolder`),
          ],
          cardValues: [accounts.type, accounts.number, accounts.category],
          iconSvg: iconsSvg.canceledSales,
        })
      );
      if (!responsePersonById.data.additionalContentMap?.length) {
        showToastSuccessMessage(
          t(`paymentMethod.accountsAlert`),
          typeAlert.info,
          setSeeToast
        );
      }
      setAccountList(accountListNew);
      dispatch(personByIdClearData());
    }
  }, [responsePersonById]);

  useEffect(() => {
    if (errorOneQuote) {
      showErrorToast(errorOneQuote, setSeeToast, t);
    }
  }, [errorOneQuote]);

  useEffect(() => {
    if (errorCreatePolicy) {
      showErrorToast(errorCreatePolicy, setSeeToast, t);
    }
  }, [errorCreatePolicy]);

  useEffect(() => {
    if (errorCatalogAccountType) {
      showErrorToast(errorCatalogAccountType, setSeeToast, t);
    }
  }, [errorCatalogAccountType]);

  useEffect(() => {
    if (
      responseCatalogAccountType &&
      responseCatalogAccountType.data?.length > 0
    ) {
      const data: select[] = responseCatalogAccountType.data?.map(
        (account: PlanModel) => {
          return {
            id: account.catalogAccountTypeId,
            value: account.catalogAccountTypeId,
            name: account.catalogAccountType?.description,
          };
        }
      );
      setListAccountTypes(data);
      dispatch(catalogAccountTypeClearData());
      if (["apap-auto", "bancounion"].includes(company)) {
        setPaymentListOption(paymentSummaryStorybook.paymentOptionV2);
        setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV2);
      }
    } else if (
      responseCatalogAccountType &&
      responseCatalogAccountType.data?.length === 0
    ) {
      setPaymentListOptions(paymentSummaryStorybook.paymentOptionsV3);
      setPaymentListOption(paymentSummaryStorybook.paymentOptionV3);
    }
  }, [responseCatalogAccountType]);

  useEffect(() => {
    if (responseOneQuote) {
      setDataForm({
        Name: `${responseOneQuote?.firstNameA} ${responseOneQuote?.firstNameB}`,
        LastNames: `${responseOneQuote?.lastNameA} ${responseOneQuote?.lastNameB}`,
        DocumentNumber: responseOneQuote?.numberId,
        Email: responseOneQuote?.email,
        Insurance: responseOneQuote?.productId?.description,
        PlanTypeName: responseOneQuote?.planId?.name,
        CampaignName: responseOneQuote.campaignId?.name,
        CoverType: responseOneQuote?.coverType?.description,
        PaymentMode: ["apap-auto", "bancounion"].includes(
          lsLogin.getUserLoginStored().subdomain || ""
        )
          ? "Anual"
          : responseOneQuote?.catalogPaymentMode?.description,
        Currency: ["apap-auto", "bancounion"].includes(
          lsLogin.getUserLoginStored().subdomain || ""
        )
          ? "RD$"
          : responseOneQuote?.planId.price.currencySymbol,
        Prima: ["apap-auto", "bancounion"].includes(
          lsLogin.getUserLoginStored().subdomain || ""
        )
          ? "85"
          : responseOneQuote?.planId.price.amount,
        QuoteId: responseOneQuote.id,
      });
      setPersonId(responseOneQuote.insured.personId);
      setPersonTrigger(true);
    }
  }, [responseOneQuote]);

  useEffect(() => {
    if (responseCreatePolicy && responseCreatePolicy.data) {
      localStorageService.setStepStored({
        id: responseCreatePolicy.data.id,
        name: "Pay form",
        step: Steps.pay,
        completed: true,
        quoteId: "",
        campaign: "",
        policyId: responseCreatePolicy.data.id,
        email: dataForm.Email,
      });
      dispatch(policyCreateClearData());
      //navigate("/checkout/payment-confirmation/");
      navigateNext(STEP, navigate);
    }
  }, [responseCreatePolicy]);

  const createPolicy = (data: any) => {
    let dataSubmit: PolicyRequest = {
      data: {
        effectiveFrom: new Date().toISOString(),
        creationDate: new Date().toISOString(),
        userId: lsLogin.getUserLoginStored().userId,
        quoteId: dataForm.QuoteId,
        catalogStatus: "60c01b66-f07a-42f3-9ce4-bd973f515fed",
        paymentCurrency: "4442d70e-4013-4db2-b7e4-8d2265b219f0",
        accountHolderType: "e9b361e2-def7-4789-90b2-b2168c696b92",
        accountType: ["apap-auto", "bancounion"].includes(company)
          ? "7af4eb92-839c-4048-8cd4-2238d277af03"
          : data.type,
        accountTypeDescription: ["apap-auto", "bancounion"].includes(company)
          ? accountType
          : data.paymentName,
        accountName: ["apap-auto", "bancounion"].includes(company)
          ? accountName
          : data.AccountName.replace(/\s+/g, "")
          ? data.AccountName
          : `${dataForm.Name} ${dataForm.LastNames}`,
        accountMonth: 12,
        accountYear: 2022,
        accountNumber: ["apap-auto", "bancounion"].includes(company)
          ? accountNumber
          : data.AccountNumber,
      },
    };
    // if (company === "apap-auto") {
    //   dataSubmit = {
    //     ...dataSubmit,
    //     additionalPaymentData: {
    //       cvv: "",
    //       voucherDate: "",
    //       voucherAmount:""
    //     },
    //   };
    // }
    dispatch(policyCreate({ ...dataSubmit }));
  };

  const handlePrevious = () => {
    navigateBack(STEP, navigate);
  };

  const handleChangePayment = (event: ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    if (value === "1") {
      setDataFormPayment({
        AccountName: `${dataForm.Name} ${dataForm.LastNames}`,
      });
    }
    if (value === "4") {
      setSubTitlePayment("No. Recibo vaucher de pago");
    } else {
      setSubTitlePayment("");
    }
  };

  const clickCard = (item: any) => {
    setAccountType(item.cardValues[0]);
    setAccountNumber(item.cardValues[1]);
    setAccountName(item.cardValues[2]);
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <LoadingAFY
        loading={loadingCreatePolicy || loadingOneQuote || loadingPersonById}
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        bgColor={themeCore.colors.backgroundLoader}
        text={
          loadingCreatePolicy
            ? t(`paymentMethod.loading.loading1`)
            : t(`paymentMethod.loading.loading2`)
        }
      />
      <HeaderTitlePage
        breadCrumbs={breadCrumbs}
        steps={steps}
        hideStep={false}
        stepsTop={"-5px"}
      />

      <PaymentSummaryView
        accountName={`${dataForm.Name} ${dataForm.LastNames}`}
        nextLabel={paymentSummaryStorybook.nextLabel}
        title={paymentSummaryStorybook.title}
        options={listAccountTypes}
        paymentOption={paymentListOption}
        paymentOptions={paymentListOptions}
        hideSubTitlePayment={true}
        subTitlePayment={subTitlePayment}
        previousLabel={paymentSummaryStorybook.previousLabel}
        stepProgress={paymentSummaryStorybook.stepProgress}
        onBack={handlePrevious}
        filters={dataFilter(dataForm)}
        dataForm={dataForm}
        onNext={createPolicy}
        onChangePayment={handleChangePayment}
        accountList={accountList}
        onClickCard={clickCard}
      />
    </React.Fragment>
  );
};

export default PaymentSummary;
