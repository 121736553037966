export const getCurrentMonth = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
  const lastDay = new Date(year, month, 0).getDate();
  const endDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay}`;

  return { startDate, endDate };
};

export const getPreviousMonth = () => {
  const now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth();

  if (month === 0) {
    month = 12; // Diciembre
    year -= 1; // Año anterior
  }

  const startDate = `${year}-${month.toString().padStart(2, "0")}-01`;
  const lastDay = new Date(year, month, 0).getDate();
  const endDate = `${year}-${month.toString().padStart(2, "0")}-${lastDay}`;

  return { startDate, endDate };
};

export const getQuarterDates = (quarterType: "current" | "previous") => {
  const now = new Date();
  let year = now.getFullYear();
  let month = now.getMonth() + 1;

  let startMonth = 1;
  let endMonth = 2;

  if (quarterType === "current") {
    if (month <= 3) {
      startMonth = 1; // Enero
      endMonth = 3; // Marzo
    } else if (month <= 6) {
      startMonth = 4; // Abril
      endMonth = 6; // Junio
    } else if (month <= 9) {
      startMonth = 7; // Julio
      endMonth = 9; // Septiembre
    } else {
      startMonth = 10; // Octubre
      endMonth = 12; // Diciembre
    }
  } else if (quarterType === "previous") {
    if (month <= 3) {
      startMonth = 10; // Octubre del año anterior
      endMonth = 12; // Diciembre del año anterior
      year -= 1;
    } else if (month <= 6) {
      startMonth = 1; // Enero
      endMonth = 3; // Marzo
    } else if (month <= 9) {
      startMonth = 4; // Abril
      endMonth = 6; // Junio
    } else {
      startMonth = 7; // Julio
      endMonth = 9; // Septiembre
    }
  }

  const startDate = `${year}-${startMonth?.toString().padStart(2, "0")}-01`;
  const endDate = `${year}-${endMonth?.toString().padStart(2, "0")}-${new Date(
    year,
    endMonth,
    0,
  ).getDate()}`;

  return { startDate, endDate };
};

export const getCurrentSemester = () => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth() + 1;

  let startDate, endDate;

  if (month >= 1 && month <= 6) {
    startDate = `${year}-01-01`;
    endDate = `${year}-06-30`;
  } else {
    startDate = `${year}-07-01`;
    endDate = `${year}-12-31`;
  }

  return { startDate, endDate };
};
