import {
  QUOTE_UPLOAD_ATTACH,
  QUOTE_UPLOAD_ATTACH_CLEAR_DATA,
  QUOTE_UPLOAD_ATTACH_ERROR,
  QUOTE_UPLOAD_ATTACH_SUCCESS,
} from "./actionTypes";

export const postQuoteAttach = (uploadAttachments) => {
  return {
    type: QUOTE_UPLOAD_ATTACH,
    payload: { uploadAttachments },
  };
};

export const postQuoteAttachSuccess = (success) => {
  return {
    type: QUOTE_UPLOAD_ATTACH_SUCCESS,
    payload: success,
  };
};

export const postQuoteAttachError = (error) => {
  return {
    type: QUOTE_UPLOAD_ATTACH_ERROR,
    payload: error,
  };
};

export const postQuoteAttachClearData = () => {
  return {
    type: QUOTE_UPLOAD_ATTACH_CLEAR_DATA,
    payload: {},
  };
};
