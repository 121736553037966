import {
  ContextMenuAFY,
  IconAFY,
  icons,
  sizesIcon,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import React from "react";
import { DivLongText, DivStatus } from "./style";

export const filtersData = (Filters: any) => {
  // debugger;
  return [
    {
      name: "Producto",
      type: "label",
      label: Filters.Product,
    },
    {
      name: "Plan",
      type: "label",
      label: Filters.Plan,
    },
    {
      name: "Tipo de pago",
      type: "label",
      label: Filters.Payment,
    },
    {
      name: "Tipo de cobertura",
      type: "label",
      label: Filters.CoverType,
    },
    {
      name: "Campaña",
      type: "label",
      label: Filters.Campaign,
    },
    {
      name: "Prima",
      type: "label",
      label: Filters.Price,
    },
    {
      name: "Año",
      label: Filters.year,
      type: "label",
    },
    {
      name: "Modelo",
      label: Filters.model,
      type: "label",
    },
    {
      name: "Marca",
      label: Filters.brand,
      type: "label",
    },
    {
      name: "Chasis",
      label: Filters.chassisNumber,
      type: "label",
    },
    {
      name: "Placa",
      label: Filters.plate,
      type: "label",
    },
    {
      name: "Estado del Vehiculo",
      label: Filters.statusVehicle,
      type: "label",
    },
  ];
};

const getStatusColor = (state: string) => {
  switch (state) {
    case "Aprobado":
      return "green";
    case "Rechazado":
      return "red";
    case "Pendiente":
      return "yellow";
    case "Sin documento":
      return "blue";
    default:
      return "gray";
  }
};

export const ColumnsGrid = (
  handleClickContextMenu: (item: any, param: any) => void,
) => [
  {
    editable: false,
    field: "actions",
    headerName: "Acciones",
    width: 125,
    renderCell: (param: any) => {
      const isSinDocument = param.row.state === "Sin documento";

      return (
        <div style={{ display: "flex", gap: "10px" }}>
          <ContextMenuAFY
            colorButton="#fff"
            iconButton={icons.MoreVert}
            iconColor="#3A56A1"
            menuItem={[
              {
                disabled: false,
                icon: {
                  color: "#006ac6",
                  icon: icons.UploadFile,
                  size: sizesIcon.standard,
                },
                id: "1",
                name: "Subir documento",
              },
              {
                disabled: isSinDocument,
                icon: {
                  color: "#006ac6",
                  icon: icons.Visibility,
                  size: sizesIcon.standard,
                },
                id: "2",
                name: "Visualizar",
              },
              {
                disabled: isSinDocument,
                icon: {
                  color: "#006ac6",
                  icon: icons.HistoryEdu,
                  size: sizesIcon.standard,
                },
                id: "3",
                name: "Ver historial",
              },
            ]}
            onClickContextMenu={(item) => handleClickContextMenu(item, param)}
            sizeIcon={sizesIcon.standard}
          />
        </div>
      );
    },
  },
  {
    editable: false,
    field: "docType",
    headerName: "Tipo de documento",
    width: 250,
    renderCell: (params: any) => {
      return <DivLongText>{params.value}</DivLongText>;
    },
  },
  {
    editable: false,
    field: "state",
    headerName: "Estado",
    renderCell: (params: any) => {
      return (
        <DivStatus>
          <IconAFY icon={icons.Circle} color={getStatusColor(params.value)} />{" "}
          {params.value}
        </DivStatus>
      );
    },
    width: 150,
  },
  {
    editable: false,
    field: "date",
    headerName: "Fecha",
    width: 125,
  },
  {
    editable: false,
    field: "user",
    headerName: "usuario",
    width: 125,
  },
  {
    field: "comments",
    flex: 1,
    headerName: "Comentarios",
    renderCell: (params: any) => {
      return <DivLongText>{params.value}</DivLongText>;
    },
  },
];

export const DataForm: any = {
  brand: "",
  model: "",
  carColor: "",
  chassisNumber: "",
  carPrice: "",
  numberCylinders: "",
  fuelType: "",
  registrationNumber: "",
  DocumentNumber: "",
  QuoteNumber: "",
  QuoteStatusName: "",
  QuoteDate: "",
  Type: "",
  PlanTypeName: "",
  CampaignName: "-",
  IdTypeName: "",
  Name: "",
  SecondName: "",
  FirstLastName: "",
  SecondLastName: "",
  MarriedSurName: "-",
  Gender: "",
  Birthday: "",
  Address1: "-",
  Address2: "-",
  CountryName: "",
  ProvinceName: "",
  TownName: "",
  SectorName: "",
  PostalCode: "",
  PhoneOffice: "",
  PhoneHome: "-",
  PhoneNumber: "-",
  Email: "",
};