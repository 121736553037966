import {
  QUOTE_UPLOAD_ATTACH,
  QUOTE_UPLOAD_ATTACH_CLEAR_DATA,
  QUOTE_UPLOAD_ATTACH_ERROR,
  QUOTE_UPLOAD_ATTACH_SUCCESS,
} from "./actionTypes";

const initialState = {
  loadingUploadAttach: false,
  responseUploadAttach: null,
  errorUploadAttach: null,
};

const UploadAttach = (state = initialState, action) => {
  switch (action.type) {
    case QUOTE_UPLOAD_ATTACH:
      state = {
        ...state,
        loadingUploadAttach: true,
        errorUploadAttach: null,
        responseUploadAttach: null,
      };
      break;
    case QUOTE_UPLOAD_ATTACH_SUCCESS:
      state = {
        ...state,
        errorUploadAttach: null,
        loadingUploadAttach: false,
        responseUploadAttach: action.payload,
      };
      break;
    case QUOTE_UPLOAD_ATTACH_ERROR:
      state = {
        ...state,
        errorUploadAttach: action.payload,
        loadingUploadAttach: false,
        responseUploadAttach: null,
      };
      break;
    case QUOTE_UPLOAD_ATTACH_CLEAR_DATA:
      state = {
        ...state,
        errorUploadAttach: null,
        loadingUploadAttach: null,
        responseUploadAttach: false,
      };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default UploadAttach;
