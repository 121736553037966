import { call, put, takeEvery } from "redux-saga/effects";
import { postQuoteAttachError, postQuoteAttachSuccess } from "./actions";
import { QUOTE_UPLOAD_ATTACH } from "./actionTypes";
import { uploadQuoteAttachmentsAPI } from "../../resources/helper/uploadQuoteAttachments";

function* uploadQuoteAttachments({ payload: { uploadAttachments } }) {
  try {
    const response = yield call(
      uploadQuoteAttachmentsAPI,
      uploadAttachments.id,
      uploadAttachments.fileName,
      uploadAttachments.pdfFile,
      uploadAttachments.code,
    );
    yield put(postQuoteAttachSuccess(response));
  } catch (error) {
    yield put(postQuoteAttachError(error));
  }
}

function* uploadAttachSaga() {
  yield takeEvery(QUOTE_UPLOAD_ATTACH, uploadQuoteAttachments);
}

export default uploadAttachSaga;
