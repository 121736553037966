import LocalStorageService from "../../../services/localStorage";
import { STEP_PLANDETAILS } from "../../../const/catalog";
import React, { useEffect, useState } from "react";
import {
  Benefit,
  breadcrumb,
  MenuItem,
  position,
  select,
  typeAlert,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { navigateNext, stepProgress } from "../../../dataBase/stepProgress";
import { PlanDetailsView } from "./planDetailView";
import { useNavigate } from "react-router-dom";
import { Steps } from "../../../enum/steps";
import { PlanModel } from "../../../model/plan";
import { useGetPlansAllV2 } from "../../../fnx/plans/planGetAllV2";
import { GetPaymentMode } from "../../../fnx/paymentMode/getPaymentMode";
import { StatusOptionsModel } from "../../../model/insurancePoliciesSummary";
import { paymentModeClearData } from "../../../store/paymentModes/action";
import { useDispatch, useSelector } from "react-redux";
import { showErrorToast } from "../../../fnx/showError";
import { useTranslation } from "react-i18next";
import showToast from "../../../services/toast";
import { plansClearData } from "../../../store/plans/actions";
import {
  quoteAddFields,
  quoteAddFieldsClearData,
} from "../../../store/generateQuote/actions";

const storageKey2 = "afy-login";
const storageKey3 = "afy-steps";
const lsLogin = new LocalStorageService(storageKey2);
const lsSteps = new LocalStorageService(storageKey3);
const STEP = STEP_PLANDETAILS;

export const PlanDetails = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [price, setPrice] = React.useState<number>(85);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [trigger, setTrigger] = useState(false);
  const [triggerPaymentMode, setTriggerPaymentMode] = useState(false);
  const [listBenefit, setListBenefit] = useState<Benefit[]>([]);
  const quoteId = lsSteps.getStepStored().steps[Steps.customer].quoteId;
  const [paymentModeId, setPaymentModeId] = React.useState<string>("");
  const [paymentModeList, setPaymentModeList] = React.useState<select[]>([
    {
      id: "",
      name: "",
      value: "Anual",
    },
  ]);
  const [valuePlanId] = React.useState<string>(
    lsSteps.getStepStored().steps[Steps.plan].id,
  );
  const [ProjectId] = React.useState<string>(
    lsSteps.getStepStored().steps[Steps.insurance].id,
  );

  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const { responseQuoteAddFields, errorQuoteAddFields, loadingQuoteAddFields } =
    useSelector((state: any) => ({
      responseQuoteAddFields: state.Quote.responseQuoteAddFields,
      errorQuoteAddFields: state.Quote.errorQuoteAddFields,
      loadingQuoteAddFields: state.Quote.loadingQuoteAddFields,
    }));

  const { responsePlans, errorPlans, loadingPlans } = useGetPlansAllV2(
    trigger,
    valuePlanId,
  );

  const { responsePaymentMode, errorPaymentMode, loadingPaymentMode } =
    GetPaymentMode(triggerPaymentMode, ProjectId);

  useEffect(() => {
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    setTrigger(true);
    setTriggerPaymentMode(true);
  }, []);

  useEffect(() => {
    if (errorQuoteAddFields) {
      showErrorToast(errorQuoteAddFields, setSeeToast, t);
    }
  }, [errorQuoteAddFields]);

  useEffect(() => {
    if (errorPlans) {
      showErrorToast(errorPlans, setSeeToast, t);
      dispatch(plansClearData());
    }
  }, [errorPaymentMode]);

  useEffect(() => {
    if (errorPaymentMode) {
      showErrorToast(errorPaymentMode, setSeeToast, t);
      dispatch(paymentModeClearData());
    }
  }, [errorPaymentMode]);

  useEffect(() => {
    if (responseQuoteAddFields && responseQuoteAddFields.data) {
      dispatch(quoteAddFieldsClearData());
    }
  }, [responseQuoteAddFields]);

  useEffect(() => {
    const paymentModeSelected = paymentModeList.find(
      (item) => item.value === "Anual",
    );
    if (paymentModeSelected) {
      setPaymentModeId(paymentModeSelected.id || "");
    }
  }, [paymentModeList]);

  useEffect(() => {
    if (responsePaymentMode?.length > 0) {
      const paymentRecurrence = responsePaymentMode?.map(
        (item: StatusOptionsModel) => ({
          id: item?.catalogPaymentMode?.id,
          name: item?.catalogPaymentMode?.description,
          value: item?.catalogPaymentMode?.description,
        }),
      );
      setPaymentModeList(paymentRecurrence);
      dispatch(paymentModeClearData());
    }
  }, [responsePaymentMode]);

  /*Valida respuesta del getAll y mapea objetos*/
  useEffect(() => {
    if (responsePlans && responsePlans.length > 0) {
      const list = responsePlans?.map((item: PlanModel) => ({
        benefits: item.benefits,
      }));

      const listB = list[0].benefits;
      setListBenefit(listB);
      setTrigger(false);
    } else if (responsePlans && responsePlans.data?.length === 0) {
      setTrigger(false);
      setListBenefit([]);
    }
  }, [responsePlans]);

  const onApplySelected = (item: any) => {
    if (item.valueSelected.value === "Anual") {
      setPrice(85);
    } else {
      setPrice(150);
    }
    setPaymentModeId(item?.valueSelected?.id);
  };

  const onClickNext = () => {
    const body = {
      value: paymentModeId,
    };
    dispatch(
      quoteAddFields({ id: quoteId, key: "paymentFrequency", body: body }),
    );
    navigateNext(STEP, navigate);
  };

  return (
    <>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () =>
          setSeeToast({ show: false, message: "", type: typeAlert.success }),
        )}
      <PlanDetailsView
        loadingMsg={t("planDetail.loadingMsg")}
        title={t("planDetail.title")}
        btnLblNext={t("planDetail.btnLblNext")}
        currencyLbl={t("planDetail.currencyLbl")}
        periodPlan={t("planDetail.periodPlan")}
        subtitleBenefit={t("planDetail.subtitleBenefit")}
        subtitleLimit={t("planDetail.subtitleLimit")}
        titleDetail={t("planDetail.titleDetail")}
        price={price}
        loading={loadingPlans}
        steps={steps}
        breadCrumbs={breadCrumbs}
        onClickNext={onClickNext}
        listBen={listBenefit}
        applySelect={onApplySelected}
        paymentModeList={paymentModeList}
      />
    </>
  );
};
