import React, { useEffect, useState } from "react";
import {
  breadcrumb,
  MenuItem,
  position,
  typeAlert,
  LoadingAFY,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { InitialInfoView } from "./view";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { quoteAll, quoteAllClearData } from "../../store/generateQuote/actions";
import { postCalculationsAPI } from "../../resources/helperAPAP/calculations";
import "./style.css";
import showToast from "../../services/toast";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../dataBase/stepProgress";
import { hasPermission } from "../../services/getNavigationPages";
import { STEP_INITIALINFO } from "../../const/catalog";



const storageKey2 = "afy-login";

export const InitialInfoPage = () => {
  const STEP = STEP_INITIALINFO;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [filters, setFilters] = useState<any[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [showNextDisabled, setShowNextDisabled] = useState<boolean>(true);
  const storageKeyVariables = "afy-variables";
  const [valorEstimado, setValorEstimado] = useState<string>("0.00");
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [loading, setLoading] = useState<boolean>(false);
  const permissions = {
    create: hasPermission("initial-info"),
  };


  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);


  useEffect(() => {
    dispatch(quoteAllClearData());
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    // Comes from the client page

    setFilters([{
      name: "Producto",
      label: localStorageService.getStepStored().steps[Steps.insurance]?.name || "Vida",
      type: "label",
    }]);

  }, []);


  const handleClickPrevious = (e: React.MouseEvent<HTMLButtonElement>) => {
    navigateBack(STEP, navigate);
  };
  const calculateAge = (dateString: string) => {
    const today = new Date();
    const birthDate = new Date(dateString);
    let age = today.getFullYear() - birthDate.getFullYear();
    const monthDifference = today.getMonth() - birthDate.getMonth();
    if (monthDifference < 0 || (monthDifference === 0 && today.getDate() < birthDate.getDate())) {
      age--;
    }
    return age;
  };

  const handleClickNext = (e: any) => {
    setLoading(true);
    setShowNextDisabled(true)
    if (e.data) {
      let data = {
        "age": calculateAge(e.data.dateBirth),
        "interval": e.data.interval,
        "interestRate": e.data.interestRate,
        "utility": 3,
        "coverageAmount": e.data.InsureAmount,
        "reinsuranceRatePerThousand": 1,
        "productType": e.data.planType.id,
        "gender": e.data.sexType.id
      }

      postCalculationsAPI(data)
        .then((response) => {
          setLoading(false);
          const formattedPremium = (response.data[0]?.premium).toFixed(2);
          setValorEstimado(formattedPremium);
          e.data.premium = formattedPremium;
          setShowNextDisabled(false)
          localStorageService.setStepStored({
            id: "",
            name: "",
            step: Steps.initialInfo,
            completed: true,
            initialInfo: e.data,
          });
          setSeeToast({
            show: true,
            message: "Los datos han sido guardados",
            type: typeAlert.success,
          });
    

        })
        .catch((error) => {
          console.error("postCalculationsAPI error", error);
          setLoading(false);
          setShowNextDisabled(true)
          setSeeToast({
            show: true,
            message: "Error al realizar los cálculos",
            type: typeAlert.error,
          });
        });

    } else {
      navigateNext(STEP, navigate);
    }

  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })}

      <LoadingAFY
        loading={loading
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={"Calculando..."}
        bgColor={themeCore.colors.backgroundLoader}
      />

      <InitialInfoView
        title={"Datos iniciales"}
        steps={steps}
        breadcrumbs={breadCrumbs}
        stepProgress={steps}
        previousLabel={"Anterior"}
        nextLabel={"Siguiente"}
        nextDisabled={showNextDisabled}
        onClickNext={handleClickNext}
        onClickPrevious={handleClickPrevious}
        filters={filters}
        minHeightGrid="calc(100vh - 73px)"
        resumeDescription={"Valor estimado"}
        resumeValue={`DOP $ ${valorEstimado}`}
        titleContent={"Datos iniciales"}
        titleContentSectionB={"Coberturas opcionales"}
        titleContentSectionC={"Tipo de NCF requerido"}
        lblPlanType={"Tipo de plan"}
        lblDateBirth={"Fecha de nacimiento"}
        lblSex={"Sexo"}
        listPlanType={[
          { id: "DOTAL", name: "Dotal" },
          { id: "TEMPORAL", name: "Temporal" },
          { id: "DOTAL_MIXTO", name: "Dotal mixto" },
        ]}
        listSex={[
          { id: "M", name: "Masculino" },
          { id: "F", name: "Femenino" },
        ]}
        lblExoPrimas={"Exoneración pago de primas"}
        lblMuerteAccDes={"Muerte accidental y desmembramiento"}
        lblIncaYPerma={"Incapacidad total y permanente"}
        lblEnfGraves={"Enfermedades graves"}
        lblValorFiscal={"Con valor fiscal"}
        lblCF={"Consumidor final"}
        titleYearSlider={"Duración de la Póliza"}
        subtitleYearSlider={"Años"}
        minValueYearSlider={1}
        maxValueYearSlider={100}
        widthYearSlider={700}
        stepYearSlider={1}
        defaultValueYearSlider={20}
        fontSizeSubtitleYearSlider={"14px"}
        fontSizeTitleYearSlider={"14px"}
        inputFontSizeYearSlider={"12px"}
        inputWidthSizeYearSlider={"40px"}
        titleDecimalSlider={"Suma asegurada"}
        subtitleDecimalSlider={"$"}
        minValueDecimalSlider={1000}
        maxValueDecimalSlider={1000000}
        widthDecimalSlider={700}
        stepDecimalSlider={4000}
        defaultValueDecimalSlider={8000}
        fontSizeSubtitleDecimalSlider={"14px"}
        fontSizeTitleDecimalSlider={"14px"}
        inputFontSizeDecimalSlider={"12px"}
        inputWidthSizeDecimalSlider={"70px"}
        lblInterval={"Intervalo"}
        lblInterestRate={"Tasa de interés"}
      />

    </React.Fragment>
  );
};