import React, { useEffect, useState } from "react";
import {
  breadcrumb,
  ButtonAFY,
  GridAFY,
  icons,
  LoadingAFY,
  MenuItem,
  ModalComponentAFY,
  position,
  select,
  sizes,
  typeAlert,
  typeLoaders,
  variants,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { themeCore } from "../../assets/themes/theme";
import { ClientRecordView } from "./view";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { person, personClearData } from "../../store/person/actions";
import { IdentificationType } from "../../model/identificationType";
import { identificationType } from "../../store/identificationType/actions";
import { quoteAll, quoteAllClearData } from "../../store/generateQuote/actions";
import { Container } from "./style";
import { getBeneficiary } from "../../store/beneficiary/actions";
import { insured } from "../../store/insured/actions";
import "./style.css";
import showToast from "../../services/toast";
import LocalStorageService from "../../services/localStorage";
import { Steps } from "../../enum/steps";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../dataBase/stepProgress";
import { formattedDate } from "../../utils/date";
import { hasPermission } from "../../services/getNavigationPages";
import { STEP_CLIENTRECORD } from "../../const/catalog";
import { Columns } from "./clientRecordDTO";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const ClientRecordPage = () => {
  const STEP = STEP_CLIENTRECORD;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id } = useParams();
  const [enable, setEnable] = React.useState<boolean>(true);
  const [page, setPage] = React.useState<number>(1);
  const [company] = React.useState<string>(
    lsLogin.getUserLoginStored().subdomain || ""
  );
  const [triggerPermissionList, setTriggerPermissionList] =
    React.useState<boolean>(false);

  const [limit, setLimit] = React.useState<number>(5000);
  const [loadingAllQuote] = useState<boolean>(false);
  const [showInfo, setShowInfo] = useState<boolean>(false);
  const [clientInfo, setClientInfo] = useState<any>([]);
  const [rowsQuote, setRowsQuote] = useState<any[]>([]);
  const [showBeneficiariesModal, setShowBeneficiariesModal] =
    useState<boolean>(false);
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const [showInsuredModal, setShowInsuredModal] = useState<boolean>(false);
  const [beneficiariesRows, setBeneficiariesRows] = useState<any[]>([]);
  const [insuredRows, setInsuredRows] = useState<any[]>([]);
  const [documentNumber, setDocumentNumber] = useState<string>("");
  const [documentType, setDocumentType] = useState<string>("");
  const [filters, setFilters] = useState<any[]>([]);
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [showNextDisabled, setShowNextDisabled] = useState<boolean>(true);
  const storageKeyVariables = "afy-variables";
  const lsVariables = new LocalStorageService(storageKeyVariables);
  const [documentName, setDocumentName] = useState<string>("");

  const [listIdentificationType, setListIdentificationType] = useState<
    select[]
  >([]);
  const [seeToast, setSeeToast] = React.useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });
  const permissions = {
    create: hasPermission("sale-record-client-view"),
  };
  const beneficiaryColumns = [
    {
      editable: false,
      field: "type",
      flex: 1,
      headerName: "Tipo beneficiario",
      width: 150,
    },
    {
      editable: false,
      field: "name",
      flex: 1,
      headerName: "Nombres",
      width: 150,
      renderCell: (params: any) => {
        return params.value + " " + params.row.secondName;
      },
    },
    {
      editable: false,
      field: "lastName",
      flex: 1,
      headerName: "Apellidos",
      width: 150,
      renderCell: (params: any) => {
        return params.value + " " + params.row.secondLastName;
      },
    },

    {
      editable: false,
      field: "phone",
      flex: 1,
      headerName: "Teléfono",
      width: 150,
    },
    {
      editable: false,
      field: "dob",
      flex: 1,
      headerName: "Fecha nacimiento",
      width: 150,
      valueGetter: (params: any) => {
        const date = new Date(params.value);
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
      },
    },
    {
      editable: false,
      field: "percentage",
      flex: 1,
      headerName: "Porcentaje",
      width: 150,
      valueGetter: (params: any) => {
        return `${params.value} %`;
      },
    },
  ];
  const insuredColumns = [
    {
      editable: false,
      field: "type",
      flex: 1,
      headerName: "Tipo asegurado",
      width: 150,
    },
    {
      editable: false,
      field: "name",
      flex: 1,
      headerName: "Nombres",
      width: 150,
      renderCell: (params: any) => {
        return params.value + " " + params.row.secondName;
      },
    },
    {
      editable: false,
      field: "lastName",
      flex: 1,
      headerName: "Apellidos",
      width: 150,
      renderCell: (params: any) => {
        return params.value + " " + params.row.secondLastName;
      },
    },

    {
      editable: false,
      field: "marriedName",
      flex: 1,
      headerName: "Apellido de casada",
      width: 150,
    },
    {
      editable: false,
      field: "phone",
      flex: 1,
      headerName: "Teléfono",
      width: 150,
    },
    {
      editable: false,
      field: "dob",
      flex: 1,
      headerName: "Fecha nacimiento",
      width: 150,
      valueGetter: (params: any) => {
        const date = new Date(params.value);
        const day = ("0" + date.getDate()).slice(-2);
        const month = ("0" + (date.getMonth() + 1)).slice(-2);
        const year = date.getFullYear();

        return `${day}/${month}/${year}`;
      },
    },
  ];

  const storageKey = "afy-steps";
  const localStorageService = new LocalStorageService(storageKey);
  const { responsePerson, errorPerson, loadingPerson } = useSelector(
    (state: any) => ({
      responsePerson: state.Person.responsePerson?.data,
      errorPerson: state.Person.errorPerson,
      loadingPerson: state.Person.loadingPerson,
    })
  );

  const {
    responseIdentificationType,
    errorIdentificationType,
    loadingIdentificationType,
  } = useSelector((state: any) => ({
    responseIdentificationType:
      state.IdentificationType.responseIdentificationType,
    errorIdentificationType: state.IdentificationType.errorIdentificationType,
    loadingIdentificationType:
      state.IdentificationType.loadingIdentificationType,
  }));
  const { responseAllQuote, errorAllQuote } = useSelector((state: any) => ({
    responseAllQuote: state.Quote.responseAllQuote,
    errorAllQuote: state.Quote.errorAllQuote,
    //loadingAllQuote: state.Quote.loadingAllQuote,
  }));

  const { responseBeneficiary, errorBeneficiary, loadingBeneficiary } =
    useSelector((state: any) => ({
      responseBeneficiary: state.Beneficiary.responseBeneficiary,
      errorBeneficiary: state.Beneficiary.errorBeneficiary,
      loadingBeneficiary: state.Beneficiary.loadingBeneficiary,
    }));

  const { responseInsured, errorInsured, loadingInsured } = useSelector(
    (state: any) => ({
      responseInsured: state.Insured.responseInsured,
      errorInsured: state.Insured.errorInsured,
      loadingInsured: state.Insured.loadingInsured,
    })
  );

  useEffect(() => {
    dispatch(personClearData());
    dispatch(quoteAllClearData());
    dispatch(identificationType({ enable: enable, page: page, limit: limit }));
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    // Comes from the client page
    if (id) {
      setDocumentNumber(id);
      dispatch(
        person({ enable: enable, page: page, limit: limit, numberId: id })
      );

      if (["apap-auto", "bancounion"].includes(company)) {
        setFilters([
          {
            name: "Producto",
            label: "N/A",
            type: "label",
          },
          {
            name: "Plan",
            label: "N/A",
            type: "label",
            showChangeButton: false,
          },
        ]);
      } else {
        setFilters([
          {
            name: "Tipo de seguro",
            label: "N/A",
            type: "label",
          },
          {
            name: "Plan",
            label: "N/A",
            type: "label",
          },
          {
            name: "Periodicidad de pago",
            label: "N/A",
            type: "label",
          },
          {
            name: "Tipo de cobertura",
            label: "N/A",
            type: "label",
          },
          {
            name: "Campaña",
            label: "N/A",
            type: "label",
          },
        ]);
      }
    } else {
      if (["apap-auto", "bancounion"].includes(company)) {
        if (
          localStorageService
            .getStepStored()
            .steps[Steps.insurance]?.name.toLowerCase() === "vida termino" ||
          localStorageService
            .getStepStored()
            .steps[Steps.insurance]?.name.toLowerCase() === "vida término"
        ) {
          setFilters([
            {
              name: "Producto",
              label:
                localStorageService.getStepStored().steps[Steps.insurance]
                  ?.name ?? "",
              type: "label",
            },
          ]);
        } else {
          setFilters([
            {
              name: "Producto",
              label:
                localStorageService.getStepStored().steps[Steps.insurance]
                  ?.name ?? "",
              type: "label",
            },
            {
              name: "Plan",
              label:
                localStorageService.getStepStored().steps[Steps.plan]?.name ??
                "",
              type: "label",
            },
            {
              name: "Año",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.year ?? "",
              type: "label",
            },
            {
              name: "Modelo",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.carDetails?.model.name ?? "",
              type: "label",
            },
            {
              name: "Marca",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.carDetails?.brand.name ?? "",
              type: "label",
            },
            {
              name: "Chasis",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.chassisNumber ?? "",
              type: "label",
            },
            {
              name: "Placa",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails.registrationNumber ?? "",
              type: "label",
            },
            {
              name: "Estado del Vehiculo",
              label:
                localStorageService.getStepStored().steps[Steps.vehiclesDetails]
                  ?.vehiclesDetails?.carCondition?.name ?? "",
              type: "label",
            },
          ]);
        }
      } else {
        setFilters([
          {
            name: "Tipo de seguro",
            label:
              localStorageService.getStepStored().steps[Steps.insurance]
                ?.name ?? "",
            type: "label",
          },
          {
            name: "Plan",
            label:
              localStorageService.getStepStored().steps[Steps.plan]?.name ?? "",
            type: "label",
          },
          {
            name: "Periodicidad de pago",
            label:
              localStorageService.getStepStored().steps[Steps.plan]
                .paymentModeSelected?.name ?? "",
            type: "label",
          },
          {
            name: "Tipo de cobertura",
            label:
              localStorageService.getStepStored().steps[Steps.plan].coverType
                ?.name ?? "",
            type: "label",
          },
          {
            name: "Campaña",
            label:
              localStorageService.getStepStored().steps[Steps.plan]
                .campaignSelected?.name ?? "",
            type: "label",
          },
        ]);
      }
    }
  }, []);

  useEffect(() => {
    if (responsePerson && responsePerson.length > 0) {
      setShowInfo(true);
      if (id) {
        setShowNextDisabled(true);
      } else {
        setShowNextDisabled(false);
      }

      dispatch(
        quoteAll({
          clientId: responsePerson[0].clientId,
          enable: enable,
          page: page,
          limit: limit,
          numberId: responsePerson[0].numberId,
          fields: "abc",
        })
      );
      setClientInfo([
        {
          label: "Nombre completo",
          value:
            responsePerson[0].lastNameA +
            " " +
            responsePerson[0].lastNameB +
            ", " +
            responsePerson[0].firstNameA +
            " " +
            responsePerson[0].firstNameB,
        },
        {
          label: "Fecha de nacimiento",
          value: formattedDate(responsePerson[0].dob),
        },
        {
          label: "Identificación",
          value: documentNumber ? documentNumber : responsePerson[0].numberId,
        },
        { label: "Teléfono casa", value: responsePerson[0].phone1 },
        { label: "Email", value: responsePerson[0].email },
        { label: "Dirección", value: responsePerson[0].addressA },
      ]);
    } else {
      if (responsePerson && responsePerson.length == 0) {
        setShowInfo(false);
        setShowNextDisabled(true);
        setSeeToast({
          show: true,
          message: "¡No se encontraron datos para la busqueda!",
          type: typeAlert.info,
        });
      }
    }
    if (id) {
      setShowNextDisabled(true);
    } else {
      setShowNextDisabled(
        validDocument(documentName, documentNumber) ? false : true
      );
    }
  }, [responsePerson]);

  useEffect(() => {
    setRowsQuote([]);
    if (responseAllQuote && responseAllQuote.length > 0) {
      var srq: {
        id: any;
        estado: any;
        cotizacion: any;
        poliza: any;
        producto: any;
        plan: any;
        prima: any;
      }[] = [];
      responseAllQuote.map((item: any) => {
        let prima =
          item.lastPrice?.currencySymbol + " " + item.lastPrice?.amount;
        srq.push({
          id: item.id,
          estado: item.catalogStatusQuote.description,
          cotizacion: item.code,
          poliza: item.policyId?.code,
          producto: item.productId?.description,
          plan: item.planId?.name,
          prima: prima,
        });
      });
      setRowsQuote(srq);
    }
  }, [responseAllQuote]);

  useEffect(() => {
    if (showBeneficiariesModal && showInsuredModal) {
      setShowBeneficiariesModal(false);
      setShowInsuredModal(false);
    }
  }, [showBeneficiariesModal, showInsuredModal]);

  useEffect(() => {
    setInsuredRows([]);
    if (responseInsured && responseInsured.data?.length > 0) {
      responseInsured.data.map((item: any) => {
        setInsuredRows((prev) => [
          ...prev,
          {
            id: item.id,
            type: item.catalogInsuredTypeInfo.description,
            name: item.personInfo?.firstNameA,
            secondName: item.personInfo?.firstNameB,
            lastName: item.personInfo?.lastNameA,
            secondLastName: item.personInfo?.lastNameB,
            marriedName: item.personInfo?.marriedName,
            phone: item.personInfo?.phone1,
            email: item.personInfo?.email,
            dob: item.personInfo?.dob,
          },
        ]);
      });
    }
  }, [responseInsured]);

  useEffect(() => {
    setBeneficiariesRows([]);
    if (responseBeneficiary && responseBeneficiary?.data?.length > 0) {
      responseBeneficiary.data.map((item: any) => {
        setBeneficiariesRows((prev) => [
          ...prev,
          {
            id: item.id,
            type: item.catalogBeneficiaryTypeInfo.description,
            name: item.personInfo?.firstNameA,
            secondName: item.personInfo?.firstNameB,
            lastName: item.personInfo?.lastNameA,
            secondLastName: item.personInfo?.lastNameB,
            marriedName: item.personInfo?.marriedName,
            percentage: item.percentage,
            phone: item.personInfo?.phone1,
            email: item.personInfo?.email,
            dob: item.personInfo?.dob,
          },
        ]);
      });
    }
  }, [responseBeneficiary?.data]);

  const handleClickContextMenu = (item: any, row: any) => {
    let data = row.row;
    if (item.name === "Beneficiarios") {
      dispatch(
        getBeneficiary({
          enable: true,
          page: 1,
          limit: 100,
          quoteId: data.id,
        })
      );

      setShowBeneficiariesModal(true);
    }
    if (item.name === "Asegurados") {
      dispatch(
        insured({
          enable: true,
          page: 1,
          limit: 100,
          quoteId: data.id,
        })
      );
      setShowInsuredModal(true);
    }
    if (item.name === "Ver detalles") {
      if (
        data.estado === "Expirada" ||
        data.estado === "Cancelada" ||
        data.estado === "Emitida"
      ) {
        navigate(`/confirmationForm/${data.id}/false`);
      } else {
        navigate(`/confirmationForm/${data.id}/true`);
      }
    }
  };

  useEffect(() => {
    if (responseIdentificationType?.data) {
      const data: select[] = responseIdentificationType.data?.map(
        (identification: IdentificationType) => {
          return {
            id: identification.id,
            value: identification.id,
            name: identification.description,
          };
        }
      );
      setListIdentificationType(data);
      setDocumentType(data[0]?.id ?? "");
      setDocumentName(data[0]?.name ?? "");
    }
  }, [responseIdentificationType]);

  const handleClickSeeDetails = (data: any) => {};

  const handleClickPrevious = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (id) {
      navigate("/clients");
    } else {
      dispatch(personClearData());
      dispatch(quoteAllClearData());
      navigateBack(STEP, navigate);
      //navigate("/plan");
    }
  };
  const isValid = () => {
    let dt = documentType;
    let dn = documentNumber;

    listIdentificationType.find((item: select) => {
      if (item.id === documentType) {
        dt = item.name;
      }
    });
    let type = dt;
    dt = dt.toLowerCase();
    dt = dt.replace(/é/g, "e");
    dn = dn.replace(/-/g, "");
    dn = dn.replace(/_/g, "");
    let vars = [
      {
        idType: documentType,
        docNumber: dn,
        type: type,
      },
    ];
    let result =
      (dt === "cedula" && dn.length === 11) ||
      (dt === "pasaporte" && dn.length > 3);
    lsVariables.setVariablesStored(vars);

    return result;
  };

  const handleClickNext = (e: React.MouseEvent<HTMLButtonElement>) => {
    if (isValid() === true) {
      dispatch(personClearData());
      //navigate("/customerForm");
      navigateNext(STEP, navigate);
    } else {
      /*Cuando no se tiene ningun plan selecionado */
      setSeeToast({
        show: true,
        message: "¡Debe ingrensar un documento válido!",
        type: typeAlert.info,
      });
    }
  };

  const handleChangeSelect = (e: any, a: any) => {
    setShowNextDisabled(true);
    setDocumentType(a[0].id);
    setDocumentName(a[0].name);
    setDocumentNumber("");
  };

  const validDocument = (name: string, number: string) => {
    let dt = name.toLowerCase();
    dt = dt.replace(/é/g, "e");

    let dn = number.replace(/-/g, "");
    dn = dn.replace(/_/g, "");

    return (
      (dt === "cedula" && dn.length === 11) ||
      (dt === "pasaporte" && dn.length > 3)
    );
  };
  const handleChange = (e: any, a: any) => {
    setDocumentNumber(e);
    setShowNextDisabled(true);
  };

  const handleSearch = () => {
    let _term = documentNumber;
    _term = _term.replace(/-/g, "");
    _term = _term.replace(/_/g, "");

    if (_term.length > 0) {
      dispatch(
        person({ enable: enable, page: page, limit: limit, numberId: _term })
      );
    } else {
      setSeeToast({
        show: true,
        message: "¡Debe ingrensar un documento válido!",
        type: typeAlert.info,
      });
    }
  };

  const Modal = (modalProps: any) => {
    const [openModal, setOpenModal] = React.useState(modalProps.openModal);

    const handleClose = () => {
      modalProps.close();
      setOpenModal(false);
    };

    return (
      <ModalComponentAFY
        openModal={openModal}
        onClose={handleClose}
        border={0}
        backgroundColor={themeCore.colors.background}
        modalHeight="500"
        modalWidth={1000}
        minWidthModal="80%"
        padding={10}
      >
        <div className={"centerTitle"}> {modalProps.title} </div>

        <div className={"box"}>
          <GridAFY
            columns={modalProps.columns}
            height={"371px"}
            onClick={function noRefCheck() {}}
            pageSize={5}
            rows={modalProps.rows}
            width="100%"
          />
        </div>

        <div className={"divContainerButtons"}>
          <ButtonAFY
            variant={variants.outlined}
            label={"Cerrar"}
            size={sizes.large}
            onClick={handleClose}
          />
        </div>
      </ModalComponentAFY>
    );
  };

  return (
    <React.Fragment>
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => {
          setSeeToast({ show: false, message: "", type: typeAlert.success });
        })}
      <LoadingAFY
        loading={
          loadingPerson ||
          loadingIdentificationType ||
          loadingAllQuote ||
          loadingBeneficiary
        }
        type={typeLoaders.BarLoader}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text="Cargando"
        bgColor={themeCore.colors.backgroundLoader}
      />
      <Modal
        title={"Beneficiarios"}
        openModal={showBeneficiariesModal}
        columns={beneficiaryColumns}
        rows={beneficiariesRows}
        close={() => setShowBeneficiariesModal(false)}
      />
      <Modal
        title={"Asegurados"}
        openModal={showInsuredModal}
        columns={insuredColumns}
        rows={insuredRows}
        close={() => setShowInsuredModal(false)}
      />
      {id && (
        <Container>
          <ButtonAFY
            variant={variants.text}
            label={"Regresar"}
            icon={icons.ArrowBackIosNew}
            size={sizes.small}
            color={themeCore.colors.primaryText}
            onClick={handleClickPrevious}
          />
        </Container>
      )}

      {listIdentificationType.length > 0 && (
        <ClientRecordView
          title={"Registro de cliente"}
          hideFilter={id ? true : false}
          breadcrumbs={breadCrumbs}
          stepProgress={steps}
          onClickSeeDetails={handleClickSeeDetails}
          onSearch={handleSearch}
          handleChange={handleChange}
          previousLabel={"Anterior"}
          nextLabel={"Nueva cotización"}
          nextDisabled={permissions.create ? !showNextDisabled : false}
          showInfo={showInfo}
          showBeneficiariesModal={false}
          showInsuredModal={false}
          clientInfo={clientInfo}
          documentTypeList={listIdentificationType}
          onClickNext={handleClickNext}
          onClickPrevious={handleClickPrevious}
          columns={Columns(handleClickContextMenu)}
          rows={rowsQuote}
          filters={filters}
          handleChangeSelect={handleChangeSelect}
        />
      )}
    </React.Fragment>
  );
};
