import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { View } from "./view";
import { ResponseError } from "../../resources/ResponseError";
import {
  ListDropdownMultipleProps,
  UserModel,
  UserPutRolRequest,
  UserRequest,
} from "../../model/user";
import LocalStorageService from "../../services/localStorage";
import { useUserGetAll } from "../../fnx/user/userGetAll";
import { useUserPatch } from "../../fnx/user/userPatch";
import { useCreateUser } from "../../fnx/user/userCreate";
import { useBranchesGetAll } from "../../fnx/branches/branchesGetAll";
import { useRolGetAll } from "../../fnx/rol/rolGetAll";
import { useIdentificationTypeGetAll } from "../../fnx/identificationType/identificationTypeGetAll";
import {
  deleteEntity,
  deleteEntityClearData,
} from "../../store/delete/actions";
import { identificationType } from "../../store/identificationType/actions";
import {
  userCreateClearData,
  userPatchClearData,
  userPutRolClearData,
} from "../../store/auth/actions";
import { ColumnsRols, ColumnsUser, userDataForm } from "./userDTO";
import {
  LoadingAFY,
  MenuItem,
  position,
  typeAlert,
  typeLoaders,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import showToast from "../../services/toast";
import { themeCore } from "../../assets/themes/theme";
import { useTranslation } from "react-i18next";
import { showErrorToast } from "../../fnx/showError";
import { catalogGender } from "../../store/plan/actions";
import { hasPermission } from "../../services/getNavigationPages";
import { Rol } from "../../model/rol";
import { RowsModel } from "../../model/insurancePoliciesSummary";
import { defaultDropDownPermissions, description } from "../rol/data";
import { useUserPutRole } from "../../fnx/user/userRole";

const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const UserAdmin = () => {
  const dispatch = useDispatch();
  const [list, setList] = useState<UserModel[]>([]);
  const { t } = useTranslation();
  const [listGenders, setListGenders] = useState();

  const [activeStep, setActiveStep] = useState<number>(1);
  const [triggerIdentificationType, setTriggerIdentificationType] =
    useState(false);
  const [triggerRol, setTriggerRol] = useState(false);
  const [triggerBranches, setTriggerBranches] = useState<boolean>(false);
  const [trigger, setTrigger] = useState(false);
  const [triggerPatch, setTriggerPatch] = useState(false);
  const [triggerPost, setTriggerPost] = useState(false);
  const [triggerPutRole, setTriggerPutRole] = useState(false);
  const [openModalDelete, setOpenModalDelete] = useState<boolean>(false);
  const [nameSearch, setNameSearch] = useState<string>("");
  const [bodyRequest, setBodyRequest] = useState<UserRequest>({});
  const [bodyRequestUserRol, setBodyRequestUserRol] =
    useState<UserPutRolRequest>({});
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [openModalConfirm, setOpenModalConfirm] = useState<boolean>(false);
  const [openModalCloneConfirm, setOpenModalCloneConfirm] =
    useState<boolean>(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [branchId] = React.useState<string>(
    lsLogin.getUserLoginStored().branchId,
  );
  const [isUpdate, setIsUpdate] = useState<boolean>(false);
  const [openDrawer, setOpenDrawer] = useState<boolean>(false);
  const [openDrawerRols, setOpenDrawerRols] = useState<boolean>(false);
  const [itemModal, setitemModal] = useState<any>(null);
  const [dataForm, setDataForm] = useState(userDataForm);
  const [page, setPage] = useState<number>(1);
  const [limit, setLimit] = useState<number>(20);
  const [rolList, setRolList] = useState<Rol[]>([]);
  const [dbRolList, setDbRolList] = useState<Rol[]>([]);

  const [titleDelete, setTitleDelete] = React.useState("");
  const [subTitleDelete, setSubTitleDelete] = React.useState("");
  const [roleId, setRoleId] = React.useState("");

  const [openModalDeleteRole, setOpenModalDeleteRole] =
    useState<boolean>(false);

  const [seeToast, setSeeToast] = useState<{
    show: boolean;
    type: typeAlert;
    message: string;
  }>({
    show: false,
    message: "",
    type: typeAlert.success,
  });

  const [roleOptions, setRoleOptions] = useState<ListDropdownMultipleProps[]>(
    [],
  );
  const clickContextMenuPaymentMode = (item: RowsModel) => {
    setOpenModalDeleteRole(true);
    setTitleDelete("Roles");
    setSubTitleDelete(`el role ${item.description?.toLowerCase()}`);
    setRoleId(item.id ? item.id : "");
    // /*Unifica listas quita duplicados y actualiza */
    const listRolesDb: string[] = itemModal.row.Roles.map((rol: Rol) => rol.id);
    // //Quita el ID que deseo de la lista
    const updatedListRolDb = listRolesDb.filter((id) => id !== item.id);
    //Actualiza la nueva lista de Roles
    setBodyRequestUserRol({
      id: itemModal.row.id,
      roles: updatedListRolDb,
    });

    setTrigger(true);
    setOpenDrawerRols(false);
    setOpenModal(false);
  };
  const columnsRols = ColumnsRols(clickContextMenuPaymentMode);
  const [branchesOptions, setBranchesOptions] = useState<
    ListDropdownMultipleProps[]
  >([]);
  const [identificationTypeOptions, setIdentificationTypeOptions] =
    useState<any>();

  /*Llamada al getAll para recuperar todos los registros*/
  const { responseRol, errorRol, loadingRol } = useRolGetAll(
    trigger,
    clientId,
    page,
    limit,
  );

  const { responseUser, errorUser, loadingUser } = useUserGetAll(
    trigger,
    nameSearch,
    page,
    limit,
  );

  // User Put Rol
  const { responseUserPutRol, errorUserPutRol, loadingUserPutRol } =
    useUserPutRole(triggerPutRole, bodyRequestUserRol);

  const { responseUserCreate, errorUserCreate, loadingUserCreate } =
    useCreateUser(triggerPost, bodyRequest);
  const { responseUserPatch, errorUserPatch, loadingUserPatch } = useUserPatch(
    triggerPatch,
    bodyRequest,
  );
  const { responseDeleteEntity, loadingDeleteEntity, errorDeleteEntity } =
    useSelector((state: any) => ({
      responseDeleteEntity: state.DeleteEntity.responseDeleteEntity,
      loadingDeleteEntity: state.DeleteEntity.loadingDeleteEntity,
      errorDeleteEntity: state.DeleteEntity.errorDeleteEntity,
    }));

  const { responseBranches, errorBranches, loadingBranches } =
    useBranchesGetAll(trigger, clientId, "", "");
  const {
    responseIdentificationType,
    errorIdentificationType,
    loadingIdentificationType,
  } = useIdentificationTypeGetAll(triggerIdentificationType, "", "", 1, 100);

  const { responseCatalogGender, errorCatalogGender } = useSelector(
    (state: any) => ({
      responseCatalogGender: state.Plan.responseCatalogGender,
      errorCatalogGender: state.Plan.errorCatalogGender,
    }),
  );

  const permissions = {
    view: hasPermission("admin-users-view"),
    create: hasPermission("admin-users-create"),
    edit: hasPermission("admin-users-edit"),
    delete: hasPermission("admin-users-delete"),
  };

  useEffect(() => {
    if (!responseUser?.data) {
      setTrigger(true);
    }
  }, []);

  useEffect(() => {
    if (!responseBranches) {
      setTriggerBranches(true);
    }

    if (!responseIdentificationType) {
      setTriggerIdentificationType(true);
    }

    // if (!responseRol) {
    //   setTriggerRol(true);
    // }

    if (!responseCatalogGender) {
      dispatch(
        catalogGender({
          enable: true,
          page: 1,
          limit: 100,
          clientId: lsLogin.getUserLoginStored().clientId,
        }),
      );
    }
  }, []);

  useEffect(() => {
    if (responseIdentificationType && responseIdentificationType?.data) {
      setIdentificationTypeOptionsStorybook(responseIdentificationType);
      dispatch(identificationType());
    }
  }, [responseIdentificationType]);

  const setIdentificationTypeOptionsStorybook = (
    responseIdentificationType: any,
  ) => {
    let dataIdentificationType: any = responseIdentificationType?.data.map(
      (item: any) => ({
        id: item.id,
        name: item.description ? item.description : "",
        value: item.id,
      }),
    );
    setIdentificationTypeOptions(dataIdentificationType);
  };

  useEffect(() => {
    if (responseCatalogGender && responseCatalogGender.data?.length > 0) {
      const data = responseCatalogGender.data?.map((gender: any) => {
        return {
          id: gender.id,
          value: gender.id,
          name: gender.description,
        };
      });
      setListGenders(data);
    }
  }, [responseCatalogGender]);

  useEffect(() => {
    if (Array.isArray(responseBranches)) {
      setBranchesOptionsStorybook(responseBranches);
    }
    setTriggerBranches(false);
  }, [responseBranches]);

  const setBranchesOptionsStorybook = (responseBranches: any) => {
    let dataBranches: any = responseBranches?.map((item: any) => ({
      id: item.id,
      name: item.name,
      value: item.id,
    }));
    setBranchesOptions(dataBranches);
  };

  useEffect(() => {
    if (Array.isArray(responseRol)) {
      setRolOptionsStorybook(responseRol);
    }
    setTriggerRol(false);
  }, [responseRol]);

  const setRolOptionsStorybook = (responseRol: any) => {
    let dataRol: any = responseRol?.map((item: any) => ({
      id: item.id,
      name: item.name,
      value: item.id,
    }));
    setRoleOptions(dataRol);
  };

  useEffect(() => {
    if (responseUser) {
      const list: UserModel[] = responseUser.map((item: UserModel) => ({
        id: item.id,
        UserName: item.userName,
        Name: item.name,
        LastNames: item.lastName,
        Email: item.email,
        BranchName: "",
        RoleName: "",
        Enable: item.enabled?.toString(),
        Roles: item.roles,
      }));
      setList(list);
    }
    setTrigger(false);
  }, [responseUser]);

  useEffect(() => {
    if (errorUser) {
      setTrigger(false);
    }
  }, [errorUser]);

  /*Valida respuesta del getAll y mapea objetos*/
  useEffect(() => {
    if (responseRol && responseRol?.data?.length > 0) {
      const listRols: Rol[] = responseRol?.data?.map((item: Rol) => ({
        id: item.id,
        clientId: item.clientId,
        name: item.name,
        description: item.description,
        permissions: item.permissions,
      }));
      setRolList(listRols);
      setTriggerRol(false);
    } else if (responseRol && responseRol?.length === 0) {
      setTriggerRol(false);
      setRolList([]);
    }
  }, [responseRol]);

  useEffect(() => {
    if (responseUserCreate) {
      setSeeToast({
        show: true,
        message: "Usuario creado con éxito",
        type: typeAlert.success,
      });
      
      setOpenModal(false);
      setTriggerPost(false);
      setTrigger(true);
    } else if (errorUserCreate) {
      const error = errorUserCreate as ResponseError;
      let message = "Error al crear usuario";
      if (error.response?.errors !== undefined) {
        message = error.response?.errors[0].message;
      } else {
        message = error.response?.message;
      }
      if (message === "PASSWORD_NOT_MATCH") {
        console.log("Error al crear usuario: Las contraseñas no coinciden");
      }
      setSeeToast({
        show: true,
        message: message,
        type: typeAlert.error,
      });
      
      setTriggerPost(false);
      setTrigger(false);
    }
    dispatch(userCreateClearData());
  }, [responseUserCreate, errorUserCreate]);

  useEffect(() => {
    if (responseDeleteEntity && responseDeleteEntity?.data) {
      setSeeToast({
        show: true,
        message: "Eliminado con éxito",
        type: typeAlert.success,
      });
      

      setTrigger(true);
    } else if (errorDeleteEntity) {
      showErrorToast(errorDeleteEntity, setSeeToast, t);
    }
    setOpenDrawer(false);
    setOpenModalDelete(false);
    dispatch(deleteEntityClearData());
  }, [responseDeleteEntity, errorDeleteEntity]);

  useEffect(() => {
    setTriggerPatch(false);
    if (responseUserPatch) {
      setSeeToast({
        show: true,
        message: "Usuario actualizado con éxito",
        type: typeAlert.success,
      });
      
      setOpenModal(false);
      setTriggerPatch(false);
      setTrigger(true);
    } else if (errorUserPatch) {
      setSeeToast({
        show: true,
        message: errorUserPatch?.message,
        type: typeAlert.error,
      });
      
      setTriggerPatch(false);
    }
    dispatch(userPatchClearData());
  }, [responseUserPatch, errorUserPatch]);

  useEffect(() => {
    if (responseUserPutRol) {
      setSeeToast({
        show: true,
        message: "Operacion realizada con éxito",
        type: typeAlert.success,
      });
      
      setOpenModal(false);
      setTriggerPutRole(false);
      setTrigger(false);
      setTrigger(true);
    } else if (errorUserPutRol) {
      setSeeToast({
        show: true,
        message: errorUserPutRol?.message,
        type: typeAlert.error,
      });
      
      setTriggerPutRole(false);
    }
    dispatch(userPutRolClearData());
  }, [responseUserPutRol, errorUserPutRol]);

  const searchFilter = (term: string) => {
    setNameSearch(term);
  };

  useEffect(() => {
    if (nameSearch) {
      setTrigger(true);
    } else {
      onCloseSearch();
    }
  }, [nameSearch]);

  const onCloseSearch = () => {
    setNameSearch("");
    setTrigger(true);
  };

  const filterStatus = (item: any) => {
    // const idUser = item?.user.map((item: any) => item.id).join(",");
    // setTrigger(true);
  };

  const showEdit = (row: any) => {
    setDataForm({
      ...dataForm,
      id: row.id,
      email: row.Email,
      name: row.Name,
      userName: row.UserName,
      lastName: row.LastNames,
    });
  };

  const handleSubmit = (dataItem: any) => {
    if (isUpdate) {
      setBodyRequest({
        id: dataItem.id,
        name: dataItem.name,
        lastName: dataItem.lastName,
      });
      setTriggerPatch(true);
    } else {
      if (dataItem.password !== dataItem.passwordConfirm) {
        setSeeToast({
          show: true,
          message: "Error al crear usuario: Las contraseñas no coinciden",
          type: typeAlert.error,
        });

        setTriggerPost(false);
      } else {
        setBodyRequest({
          name: dataItem.Name,
          lastName: dataItem.lastName,
          email: dataItem.email,
          password: dataItem.password,
          passwordConfirm: dataItem.passwordConfirm,
          clientId: clientId,
          branchId: branchId,
        });
        setTriggerPost(true);
      }
    }
    setOpenDrawer(false);
  };

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleOpenModalConfirm = () => {
    setOpenModalConfirm(true);
  };

  const handleOpenModalCloneConfirm = () => {
    setOpenModalCloneConfirm(true);
  };

  const handleCloseModal = () => {
    setOpenDrawer(false);
  };

  const handleOnClickAdd = () => {
    setIsUpdate(false);
    setOpenDrawer(true);
    setActiveStep(1);
    setDataForm({
      ...dataForm,
      id: "",
      name: "",
      lastName: "",
      birthDate: "",
      identificationType: "",
      identificationNumber: "",
      genderType: "",
      userName: "",
      email: "",
      password: "",
      passwordConfirm: "",
      companyType: "",
      branchType: "",
      roleType: "",
      intermediaryType: "",
    });
  };

  const handleOnClickButtonOptionsDropdown = (opt?: any, item?: any) => {
    if (opt.id === "1") {
      setIsUpdate(true);
      setOpenModal(true);
      showEdit(item);
    } else if (opt.id === "2") {
      setitemModal(item);
      setOpenModalConfirm(true);
    } else if (opt.id === "3") {
      setitemModal(item);
      setOpenModalCloneConfirm(true);
    }
  };

  const onClickContextMenu = (opt?: any, item?: any) => {
    if (opt.id === "1") {
      showEdit(item.row);
      setIsUpdate(true);
      setOpenDrawer(true);
    } else if (opt.id === "2") {
      setitemModal(item);
      setOpenModalDelete(true);
    } else if (opt.id === "3") {
      setitemModal(item);
      setOpenModalCloneConfirm(true);
    } else if (opt.id === "4") {
      const listRolesDb: Rol[] = item.row.Roles?.map((item: Rol) => ({
        id: item.id,
        name: item.name,
        description: item.description,
      }));

      setDbRolList(listRolesDb);
      setitemModal(item);
      setIsUpdate(true);
      showEdit(item);
      setOpenDrawerRols(true);
    }
  };

  const onCloseDrawerRols = () => {
    setDbRolList([]);
    setOpenDrawerRols(false);
  };

  const handleDeleteRols = () => {
    setOpenModalDeleteRole(false);
    setTriggerPutRole(true);
    // setTriggerDisable(true);
  };
  const onHandleSaveRolUser = (data: any) => {
    const listRolDb: string[] = itemModal.row.Roles.map((item: Rol) => item.id);
    const listRol: string[] = data.map((item: Rol) => item.id);
    const uniqueList: string[] = Array.from(
      new Set([...listRolDb, ...listRol]),
    );
    setBodyRequestUserRol({
      id: itemModal.row.id,
      roles: uniqueList,
    });
    setTriggerPutRole(true);
    setOpenDrawerRols(false);
    setOpenModal(false);
  };
  const clickView = (view: MenuItem) => {
    setitemModal(view);
  };

  const handleDelete = () => {
    setOpenModalDelete(false);
    dispatch(
      deleteEntity({
        id: itemModal.id,
        entity: "UserEntity",
        idUser: clientId,
      }),
    );
  };

  const ColumnsUsers = ColumnsUser(onClickContextMenu);
  return (
    <React.Fragment>
      <LoadingAFY
        loading={
          loadingUser ||
          loadingUserCreate ||
          loadingUserPatch ||
          loadingDeleteEntity
        }
        type={typeLoaders.FolderAnime}
        color={themeCore.colors.third}
        textColor={themeCore.colors.third}
        text={
          loadingUser ||
          loadingUserCreate ||
          loadingUserPatch ||
          loadingDeleteEntity
            ? "Eliminando usuario"
            : "Cargando usuarios..."
        }
        bgColor={themeCore.colors.backgroundLoader}
      />
      {seeToast.show &&
        showToast(position.topRight, seeToast.type, seeToast.message, () => { setSeeToast({ show: false, message: "", type: typeAlert.success }) })  }

      <View
        // Props Admin
        title="Usuarios"
        columns={ColumnsUsers}
        buttonTextAdd="Agregar usuario"
        onClickAdd={handleOnClickAdd}
        permissions={permissions}
        // Props Form
        dataForm={dataForm}
        branchTypeOptions={branchesOptions}
        identificationTypeOptions={identificationTypeOptions}
        genderTypeOptions={listGenders}
        editMode={false}
        activeStep={activeStep}
        titleForm="Edición de usuario"
        birthDateRequired="La fecha de nacimiento es requerida"
        birthDateText="Fecha de nacimiento"
        branchTypeText="Sucursal"
        buttonBackText="Anterior"
        buttonNextText="Siguiente"
        buttonText="Guardar"
        companyTypeText="Empresa"
        emailError="Formato de correo no aceptado"
        emailHelperText=""
        emailRequired="El Email es requerido"
        emailText="Email"
        genderTypeText="Sexo"
        identificationNumberHelperText=""
        identificationNumberRequired="El Numero de Identification es requerida"
        identificationNumberText="Numero de Identificación"
        identificationTypeText="Tipo de Identificación"
        intermediaryTypeText="Intermediario"
        lastNameHelperText=""
        lastNameRequired="El apellido es requerido"
        lastNameText="Apellidos"
        nameHelperText=""
        nameRequired="El nombre es requerido"
        nameText="Nombres"
        passwordConfirmError="La password debe tener al menos 8 caracteres y cumplir los requisitos de complejidad"
        passwordConfirmHelperText=""
        passwordConfirmRequired="La password es requerida"
        passwordConfirmText="Repite password"
        passwordError="La password debe tener al menos 8 caracteres y cumplir los requisitos de complejidad"
        passwordHelperText=""
        passwordRequired="La password es requerida"
        passwordText="Password"
        roleTypeText="Rol"
        userNameHelperText=""
        userNameRequired="El Username es requerido"
        userNameText="Username"
        textDescription="Lorem ipsum dolor sit amet consectetur. Eu arcu venenatis diam convallis non faucibus sem fringilla suspendisse. Vitae pharetra enim quis augue enim. Tristique eu cras consectetur tincidunt sed velit. In tristique facilisis leo viverra. In malesuada diam ante nec mauris. Id volutpat enim parturient penatibus eu orci. Neque risus morbi diam"
        onSaveData={handleSubmit}
        roleTypeOptions={roleOptions}
        // Props Drawer
        openDrawer={openDrawer}
        onCloseDrawer={handleCloseModal}
        // Props Search
        onCloseSearch={onCloseSearch}
        searchFilter={searchFilter}
        filterStatus={filterStatus}
        list={list}
        openModal={openModal}
        openModalConfirm={openModalConfirm}
        openModalCloneConfirm={openModalCloneConfirm}
        modalTitle={itemModal?.row?.UserName}
        openModalDelete={openModalDelete}
        setOpenModalDelete={setOpenModalDelete}
        onClickContextMenu={onClickContextMenu}
        clickView={clickView}
        onSubmit={handleDelete}
        handleOpenModal={handleOpenModal}
        handleOpenModalConfirm={handleOpenModalConfirm}
        handleOpenModalCloneConfirm={handleOpenModalCloneConfirm}
        onClickButtonOptionsDropdown={(opt?: any, item?: any): void => {
          handleOnClickButtonOptionsDropdown(opt, item);
        }}
        //Drawer Roles
        openModalDeleteRole={openModalDeleteRole}
        setOpenModalDeleteRole={setOpenModalDeleteRole}
        listDropDownRols={rolList}
        columnRols={columnsRols}
        buttonLblAddGrid={"Agregar Roles"}
        labelDropDownRols={"Roles"}
        txtSearchDropDownRols={"Buscar"}
        defaultDropDownRols={defaultDropDownPermissions}
        titleDrawerRol={"Agregar Roles"}
        textDescriptionRol={description}
        titleDelete={titleDelete}
        subTitleDelete={subTitleDelete}
        onCloseDrawerRolsUser={onCloseDrawerRols}
        onClickSaveDataRol={onHandleSaveRolUser}
        modalTitleRol={"Roles"}
        onSubmitDeleteRol={handleDeleteRols}
        rowsRols={dbRolList}
        openDrawerRoles={openDrawerRols}
      />
    </React.Fragment>
  );
};

export default UserAdmin;