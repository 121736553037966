import { URLs } from "../url";
import { PlanBenefitResponse } from "../../model/planBenefit";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const getPlanBenefit = async (
  enable: boolean,
  page: number,
  limit: number,
  planId: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.planBenefit +
    `?enable=${enable}&page=${page}&limit=${limit}` +
    (planId ? `&planId=${planId}` : "");
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PlanBenefitResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const postPlanBenefit = async (data: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.planBenefit;
  const requestOptions = {
    method: "POST",
    headers:  getHeader(),
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PlanBenefitResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const patchPlanBenefit = async (data: any, id:string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.planBenefit + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    headers:  getHeader(),
    body: JSON.stringify(data),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PlanBenefitResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const deletePlanBenefit = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.planBenefit + `/${id}`;
  const requestOptions = {
    method: "DELETE",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PlanBenefitResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getPlanBenefit, postPlanBenefit, patchPlanBenefit, deletePlanBenefit};
