import { URLs } from "../url";
import { fetchRequestResponse } from "../fetchRequest";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import {
  RoleResponse,
  UserGetRequest,
  UserModel,
  UserPutRolRequest,
  UserRequest,
  UserResponse,
  UsersGetAllResponse,
} from "../../model/user";
import { getHeader, getHeaderLogin } from "../headers";

const postLoginAPI = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.login;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ userName: body.userName, password: body.password }),
    headers: getHeaderLogin(),
  };

  try {
    return (await fetchRequestResponse(
      newURL,
      requestOptions,
    )) as InsuranceTypeResponse;
  } catch (err: unknown) {
    throw err;
  }
};
const byPassLogin = async (token: any, clientId: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.byPassLogin;
  const requestOptions = {
    method: "POST",
    body: [],
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json, text/plain, */*",
      "X-Client-Id": clientId,
      Authorization: "Bearer " + token
    },
  }

  try {
    return (await fetchRequestResponse(
      newURL,
      requestOptions,
    )) as InsuranceTypeResponse;
  } catch (err: unknown) {
    throw err;
  }
};
const UserGet = async (body: UserGetRequest) => {
  const usersUrl = body?.users ? `&users=${body.users}` : "";
  const orderBy = body?.orderBy ? `&orderBy=${body.orderBy}` : "";
  const orderDir = body?.orderDir ? `&orderDir=${body.orderDir}` : "";
  const enabled = body?.enabled ? `&enabled=${body.enabled}` : "";
  const deleted = body?.deleted ? `&deleted=${body.deleted}` : "";
  const page = body?.page ? `&page=${body.page}` : "";
  const limit = body?.limit ? `&limit=${body.limit}` : "";

  const newURL = `${process.env.REACT_APP_HOST_API}${URLs.user}?${usersUrl}${orderBy}${orderDir}${enabled}${page}${limit}${deleted}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequestResponse(
      newURL,
      requestOptions,
    )) as UsersGetAllResponse;
  } catch (error: any) {
    throw error;
  }
};

const userCreateUser = async (body: UserRequest) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.user;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  };

  try {
    return (await fetchRequestResponse(newURL, requestOptions)) as UserResponse;
  } catch (error) {
    throw error;
  }
};

const userInitPasswordReset = async (email: string) => {
  const newURL =
    process.env.REACT_APP_HOST_API + URLs.user + "/InitPasswordReset";
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ userName: email }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequestResponse(newURL, requestOptions)) as UserResponse;
  } catch (error: any) {
    throw error;
  }
};

const userConfirmPasswordReset = async (body: UserRequest) => {
  const newURL =
    process.env.REACT_APP_HOST_API + URLs.user + "/ConfirmPasswordReset";
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  };

  try {
    return (await fetchRequestResponse(newURL, requestOptions)) as UserResponse;
  } catch (error: any) {
    throw error;
  }
};

const userConfirmEmail = async (body: UserModel) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.user + "/ConfirmEmail";
  const requestOptions = {
    method: "POST",
    body: JSON.stringify(body),
    headers: getHeader(),
  };

  try {
    return (await fetchRequestResponse(newURL, requestOptions)) as UserResponse;
  } catch (err: unknown) {
    throw err;
  }
};

const userPatch = async (body: UserRequest, id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.user + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({
      firstName: body.name,
      lastName: body.lastName,
    }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequestResponse(newURL, requestOptions)) as UserResponse;
  } catch (error: any) {
    throw error;
  }
};

const userPutRol = async (body: UserPutRolRequest) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.user + `/Roles`;
  const requestOptions = {
    method: "PUT",
    body: JSON.stringify({
      id: body.id,
      roles: body.roles,
    }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequestResponse(newURL, requestOptions)) as RoleResponse;
  } catch (error: any) {
    throw error;
  }
};

export {
  postLoginAPI,
  userCreateUser,
  userInitPasswordReset,
  userConfirmPasswordReset,
  userConfirmEmail,
  userPatch,
  UserGet,
  userPutRol,
  byPassLogin
};