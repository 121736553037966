import { fetchRequest } from "../fetchRequest";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import { URLs } from "../url";
import { CountriesResponse } from "../../model/countries";
import { OneProjectResponse } from "../../model/project";
import { OneSponsorResponse, SponsorRequest } from "../../model/sponsor";
import { ClientResponse, OneClientResponse } from "../../model/client";
import { PersonRequest } from "../../model/person";
import { buildQueryString } from "../../utils/queryParam";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

/*Page Insurance*/
const getInsurance = async (
  enable: boolean,
  page: number,
  limit: number,
  sponsorId: string,
  descriptionInsurance: string,
  clientId: string,
  projectType: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.insurance +
    `?enable=${enable}&page=${page}&limit=${limit}` +
    (sponsorId ? `${sponsorId}` : "") +
    (descriptionInsurance ? `&description=${descriptionInsurance}` : "") +
    (clientId ? `&clientId=${clientId}` : "") +
    (projectType ? `&projectType=${projectType}` : "");

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as InsuranceTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
/*END Page Insurance*/

/*Page Insurance Type*/
const getInsuranceType = async (
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.insuranceType +
    `?enable=${enable}&page=${page}&limit=${limit}` +
    (clientId ? `&clientId=${clientId}` : "");

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as InsuranceTypeResponse;
  } catch (error: any) {
    throw error;
  }
};
/*END Page Insurance Type*/

/* Page Plan Ctalog */
const getPlanCatalog = async (enable: boolean, page: number, limit: number) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.plan +
    `?enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as InsuranceTypeResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
/* Campaign */
const getCampaignCatalog = async (
  enable: boolean,
  page: number,
  limit: number,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.campaign +
    `?enable=${enable}&page=${page}&limit=${limit}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as InsuranceTypeResponse;
  } catch (error: any) {
    throw error;
  }
};
/* Generate Quote */

/* Countries */
const getCountries = async (enable: boolean, page: number, limit: number) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.countries +
    `?enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as CountriesResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
/* END Countries */

const getOneProject = async (id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.project + `/${id}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };
  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as OneProjectResponse;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
/* END Region */

/* Sponsor */
const getSponsor = async (enable: boolean, page: number, limit: number) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.sponsor +
    `?enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as SponsorRequest;
  } catch (error: any) {
    throw error;
  }
};

const getOneSponsor = async (id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.sponsor + `/${id}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as OneSponsorResponse;
  } catch (error: any) {
    throw error;
  }
};
/* END Sponsor */

/* Sponsor */
const getClient = async (enable: boolean, page: number, limit: number) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.client +
    `?enable=${enable}&page=${page}&limit=${limit}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as ClientResponse;
  } catch (error: any) {
    throw error;
  }
};

const getOneClient = async (id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.client + `/${id}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(newURL, requestOptions)) as OneClientResponse;
  } catch (error: any) {
    throw error;
  }
};
/* END Sponsor */

/* Person */
const getPerson = async (person: any) => {
  const newURL = `${process.env.REACT_APP_HOST_API}${
    URLs.person
  }?${buildQueryString(person)}`;
  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return (await response.json()) as PersonRequest;
  } catch (error) {
    console.error(error);
    throw error;
  }
};
/* END Countries */

export {
  getInsuranceType,
  getInsurance,
  getCountries,
  getOneProject,
  getSponsor,
  getOneSponsor,
  getClient,
  getOneClient,
  getPerson,
  getPlanCatalog,
  getCampaignCatalog,
};
