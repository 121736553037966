import { URLs } from "../url";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const getVehiclesAPI = async (
  brand: string,
  orderBy: string,
  orderDir: string,
  page: number,
  limit: number,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.vehicle +
    `?query=${brand}&orderBy=${orderBy}&orderDir=${orderDir}${
      page ? `&page=${page}` : ""
    }${limit ? `&limit=${limit}` : ""}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getCarColorAPI = async (
  name: string,
  orderBy: string,
  orderDir: string,
  page: number,
  limit: number,
) => {
  let newURL =
    process.env.REACT_APP_HOST_API +
    URLs.carColor +
    `?query=${name}&orderBy=${orderBy}&orderDir=${orderDir}${
      page ? `&page=${page}` : ""
    }${limit ? `&limit=${limit}` : ""}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

export { getVehiclesAPI, getCarColorAPI };
