import React from "react";
import {
  ButtonAFY,
  icons,
  InformationAFY,
  ListDropdownMultipleProps,
  ReportFiltersAFY,
  sizes,
  sizesIcon,
  variants,
  variantsAvatar,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { useTranslation } from "react-i18next";
import { Title } from "../../../components/layout/style";
import { buttonActionOptions, Columns } from "./completedDTO";
import { themeCore } from "../../../assets/themes/theme";
import { Container } from "./style";

export const CompletedView = (props: {
  rows: any;
  filterStatus: (item: any) => void;
  downloadFile: (item: buttonActionOptions) => void;
  id: any;
  branchOptions: ListDropdownMultipleProps[];
  insuranceOptions: ListDropdownMultipleProps[];
  onClickBack: () => void;
  description: string;
  onCloseSearch: () => void;
  searchFilter: (term: string) => void;
  idSalesCompleted: string;
  idSalesCanceled: string;
  
}) => {
  const { t } = useTranslation();
  return (
    <React.Fragment>
      <Container>
        <ButtonAFY
          variant={variants.text}
          label={t('salesReport.BACK')}
          icon={icons.ArrowBackIosNew}
          size={sizes.small}
          color={themeCore.colors.primaryText}
          onClick={props.onClickBack}
        />
      </Container>

      <Title>
        {props.id === props.idSalesCompleted
          ? t("salesReport.COMPLETED")
          : props.id === props.idSalesCanceled
          ? t("salesReport.CANCELED")
          : t("salesReport.SALES")}
      </Title>
      <InformationAFY
        backgroundColor="#F5F5F5"
        colorText="#666666"
        description={props.description}
        icon={icons.Help}
        iconColor="#3A56A1"
        iconSize={sizesIcon.standard}
      />
      <div style={{ marginTop: "15px", width: "calc(100vw - 275px)" }}>
        <ReportFiltersAFY
          onChangeSearch={(term) => props.searchFilter(term)}
          onCloseSearch={props.onCloseSearch}
          textFilterSearch={t("salesReport.FILTER.SEARCH")}
          titleButton={t("salesReport.FILTER.CLEAN")}
          titlePage={t("salesReport.FILTER.POLICY")}
          titleSelectBranch={t("salesReport.FILTER.BRANCH")}
          titleSelectInsurance={t("salesReport.FILTER.PRODUCT")}
          titleSelectStatus={t("salesReport.FILTER.STATUS")}
          placeholderTextBranch={t("salesReport.FILTER.BRANCH")}
          placeholderTextInsurance={t("salesReport.FILTER.PRODUCT")}
          placeholderTextPicker={t("salesReport.FILTER.DATE")}
          placeholderTextSearch={t("salesReport.FILTER.SEARCH")}
          placeholderTextStatus={t("salesReport.FILTER.STATUSSELECT")}
          showSelectBranch={true}
          showSelectInsurance={true}
          avatarVariant={variantsAvatar.circular}
          backgroundColorSearch="#F6F7FB"
          statusOptions={[]}
          branchOptions={props.branchOptions}
          insuranceOptions={props.insuranceOptions}
          buttonActionOptions={buttonActionOptions}
          colorTextTitlePage="#3A56A1"
          columns={Columns}
          pageSize={9}
          heightGrid={"calc(100vh - 353px)"}
          iconAvatar={icons.Search}
          iconBgColor="#F6F7FB"
          iconColor="#3A56A1"
          iconColorSearch="#3A56A1"
          iconSearch={icons.Search}
          labelButtonActions={t("export")}
          onBlurSearch={function noRefCheck() {}}
          onChangeData={props.filterStatus}
          onClickButtonOptions={props.downloadFile}
          onClickCancel={function noRefCheck() {}}
          onClickClear={function noRefCheck() {}}
          onClickSeeDetails={function noRefCheck() {}}
          rows={props.rows}
          sizeIcon={sizesIcon.standard}
          sizesIconSearch={sizesIcon.standard}
          viewButtonBack={true}
          labelButtonBack={"Volver"}
          onClickBack={props.onClickBack}
        />
      </div>
    </React.Fragment>
  );
};
