import React, { useEffect, useState } from "react";
import {
  breadcrumb,
  DataFormVehicles,
  MenuItem,
} from "@affinitysystemsgroup/github-packages-affinity-components/dist";
import { VehicleDetailsView } from "./vehicleDetailsView";
import {
  navigateBack,
  navigateNext,
  stepProgress,
} from "../../../dataBase/stepProgress";
import { STEP_VEHICLEDETAILS } from "../../../const/catalog";
import { useDispatch, useSelector } from "react-redux";
import { getCarColor, getVehicles } from "../../../store/vehiclesApap/actions";
import { useNavigate } from "react-router-dom";
import { Steps } from "../../../enum/steps";
import LocalStorageService from "../../../services/localStorage";
import { useGetPlansAll } from "../../../fnx/plans/plansGetAll";
import { useTranslation } from "react-i18next";

const storageKey = "afy-steps";
const localStorageService = new LocalStorageService(storageKey);
const STEP = STEP_VEHICLEDETAILS;
const storageKey2 = "afy-login";
const lsLogin = new LocalStorageService(storageKey2);

export const VehicleDetails = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [steps, setSteps] = React.useState<MenuItem[]>([]);
  const projectId =
    localStorageService.getStepStored().steps[Steps.insurance].id;
  const [trigger, setTrigger] = useState(false);
  const [clientId] = React.useState<string>(
    lsLogin.getUserLoginStored().clientId,
  );
  const [breadCrumbs, setBreadCrumbs] = React.useState<breadcrumb[]>([]);
  const [loading, setLoading] = React.useState(false);
  const [carDetailsOption, setCarDetailsOption] = React.useState([]);
  const [carColorOption, setCarColorOption] = React.useState([]);
  const [limitSearchCar] = React.useState<number>(50000);
  const [limitSearchColor] = React.useState<number>(50000);
  const [limit] = React.useState<number>(50);
  const [insurance] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance].name,
  );
  const [campaign] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance]?.campaign,
  );
  const [campaignSelected] = React.useState<string>(
    localStorageService.getStepStored().steps[Steps.insurance]?.campaignSelected
      .id,
  );

  const { responsePlans, errorPlans, loadingPlans } = useGetPlansAll(
    trigger,
    clientId,
    projectId,
  );

  const { loadingGetVehicles, responseGetVehicles, errorGetVehicles } =
    useSelector((state: any) => ({
      loadingGetVehicles: state.Vehicles.loadingGetVehicles,
      responseGetVehicles: state.Vehicles.responseGetVehicles?.data,
      errorGetVehicles: state.Vehicles.errorGetVehicles,
    }));

  const { loadingCarColor, responseCarColor, errorCarColor } = useSelector(
    (state: any) => ({
      loadingCarColor: state.Vehicles.loadingCarColor,
      responseCarColor: state.Vehicles.responseCarColor?.data,
      errorCarColor: state.Vehicles.errorCarColor,
    }),
  );

  useEffect(() => {
    setBreadCrumbs(stepProgress(STEP, true));
    setSteps(stepProgress(STEP));
    setTrigger(true);
    callGetVehicles("", limit);
    callGetCarColor("", limit);
  }, []);

  useEffect(() => {
    if (responseGetVehicles) {
      setCarDetailsOption(responseGetVehicles);
    }
  }, [responseGetVehicles]);

  useEffect(() => {
    if (responseCarColor) {
      setCarColorOption(responseCarColor);
    }
  }, [responseCarColor]);

  useEffect(() => {
    if (responsePlans && responsePlans?.length > 0) {
      const plan = responsePlans[0];
      localStorageService.setStepStored({
        id: plan.id,
        name: plan.name,
        step: Steps.plan,
        completed: false,
        campaign: campaign,
        campaignSelected: campaignSelected,
        coverType: {
          code: "C",
          description: "Titular solamente",
          id: "c24e3622-e228-4549-a814-41043d46339a",
        },
        paymentModeSelected: {
          code: "M",
          description: "Mensual",
          id: "386ba1a4-1a5f-421d-bd93-058f4d478130",
        },
      });
      //navigateNext(STEP, navigate);
    }
  }, [responsePlans]);

  const searchCarDetails = (name: string) => {
    if (name.length === 0) {
      callGetVehicles("", limit);
    }
    if (name.length >= 2) {
      callGetVehicles(name, limitSearchCar);
    }
  };

  const searchCarColor = (name: string) => {
    if (name.length === 0) {
      callGetCarColor("", limit);
    }
    if (name.length >= 2) {
      callGetCarColor(name, limitSearchColor);
    }
  };

  const onBackStep = () => {
    navigateBack(STEP, navigate);
  };

  const onNextStep = (data: DataFormVehicles) => {
    localStorageService.setStepStored({
      id: "",
      name: "",
      step: Steps.vehiclesDetails,
      completed: true,
      vehiclesDetails: data,
    });
    navigateNext(STEP, navigate);
  };

  const callGetVehicles = (brand: string, limitGet: number) => {
    dispatch(
      getVehicles({
        brand: brand,
        orderBy: "brand",
        orderDir: "asc",
        limit: limitGet,
      }),
    );
  };

  const callGetCarColor = (color: string, limitGet: number) => {
    dispatch(
      getCarColor({
        name: color,
        orderBy: "name",
        orderDir: "asc",
        limit: limitGet,
      }),
    );
  };

  const onSelectCarDetails = (value: any) => {
    if (Object.keys(value).length === 0) {
      callGetVehicles("", limit);
    }
  };

  const onSelectCarColor = (value: any) => {
    if (Object.keys(value).length === 0) {
      callGetCarColor("", limit);
    }
  };

  return (
    <>
      <VehicleDetailsView
        title={t(`carDetails.titlePage`)}
        loading={loading}
        steps={steps}
        breadCrumbs={breadCrumbs}
        searchCarDetails={searchCarDetails}
        searchCarColor={searchCarColor}
        loadingGetVehicles={loadingGetVehicles}
        loadingCarColor={loadingCarColor}
        carDetailsOption={carDetailsOption}
        carColorOption={carColorOption}
        onNextStep={onNextStep}
        onBackStep={onBackStep}
        insurance={insurance}
        t={t}
        onSelectCarDetails={onSelectCarDetails}
        onSelectCarColor={onSelectCarColor}
      />
    </>
  );
};
