import { fetchRequest } from "../fetchRequest";
import { URLs } from "../url";
import { InsuranceTypeResponse } from "../../model/insuranceType";
import { getHeader } from "../headers";
import { handleUnauthorized } from "../fetchRequest";

const getInsuranceTypeApi = async (
  enable: boolean,
  page: number,
  limit: number,
  clientId: string,
  description: string,
) => {
  const newURL =
    process.env.REACT_APP_HOST_API +
    URLs.insuranceType +
    `?enable=${enable}&page=${page}&limit=${limit}` +
    (clientId ? `&clientId=${clientId}` : "") +
    (description ? `&description=${description}` : "");

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    const response = await fetch(newURL, requestOptions);
    if (!response.ok) {
      handleUnauthorized(response.status);
      throw await response.json();
    }
    return await response.json();
  } catch (error) {
    console.error(error);
    throw error;
  }
};

const getInsuranceTypeIdApi = async (id: string) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.insuranceType + `/${id}`;

  const requestOptions = {
    method: "GET",
    headers: getHeader(),
  };

  try {
    return await fetchRequest(newURL, requestOptions);
  } catch (error: any) {
    throw error;
  }
};

const postInsuranceTypeApi = async (body: any) => {
  const newURL = process.env.REACT_APP_HOST_API + URLs.insuranceType;
  const requestOptions = {
    method: "POST",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };
  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as InsuranceTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

const patchInsuranceTypeApi = async (body: any, id: string) => {
  let newURL = process.env.REACT_APP_HOST_API + URLs.insuranceType + `/${id}`;
  const requestOptions = {
    method: "PATCH",
    body: JSON.stringify({ data: body }),
    headers: getHeader(),
  };

  try {
    return (await fetchRequest(
      newURL,
      requestOptions,
    )) as InsuranceTypeResponse;
  } catch (error: any) {
    throw error;
  }
};

export {
  getInsuranceTypeApi,
  postInsuranceTypeApi,
  patchInsuranceTypeApi,
  getInsuranceTypeIdApi,
};
